/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from '@mui/material/Icon';

// Images
import Login from 'shared/pages/login';
import ChangePassword from 'shared/pages/change_password';
import Users from 'shared/pages/users';
import UsersCreate from 'shared/pages/users/create';
import UsersEdit from 'shared/pages/users/edit';
import Dashboard from 'shared/pages/dashboard';

import Patient from 'shared/pages/patients';
import PatientsCreate from 'shared/pages/patients/create';
import PatientsEdit from 'shared/pages/patients/edit';

// import AttendanceFolder from './shared/pages/attendance_folder';
// import Behaviors from './shared/pages/attendance_folder/behaviors';
// import BehaviorsCreate from 'shared/pages/attendance_folder/behaviors/create';
// import BehaviorsEdit from './shared/pages/attendance_folder/behaviors/edit';
// import Programs from 'shared/pages/attendance_folder/programs';
// import ProgramsCreate from 'shared/pages/attendance_folder/programs/create';
// import ProgramsEdit from 'shared/pages/attendance_folder/programs/edit';

import Calendar from 'shared/pages/calendar';

import Treatments from 'shared/pages/treatment';

import Therapists from 'shared/pages/therapists';
import TherapistsView from 'shared/pages/therapists/view';
import EvaluationCriteria from 'shared/pages/evaluation_criteria';
import CriterionCreate from 'shared/pages/evaluation_criteria/create';
import CriterionEdit from 'shared/pages/evaluation_criteria/edit';

const routes = [
  {
    name: 'Login',
    key: 'login',
    route: '/entrar',
    component: <Login />,
  },
  {
    name: 'Trocar Senha',
    key: 'change-password',
    route: '/validate',
    component: <ChangePassword />,
  },
  {
    name: 'Trocar Senha token',
    key: 'change-password-token',
    route: '/validate/:token',
    component: <ChangePassword />,
  },

  // sidenav
  {
    roles: [99],
    type: 'collapse',
    noCollapse: true,
    name: 'Dashboard',
    key: 'dashboards',
    route: '/dashboard',
    icon: <Icon fontSize='medium'>dashboard</Icon>,
    component: <Dashboard />,
  },

  //region users
  {
    roles: [99],
    type: 'collapse',
    noCollapse: true,
    name: 'Usuários',
    key: 'users',
    route: '/usuarios',
    icon: <Icon fontSize='medium'>people</Icon>,
    component: <Users />,
  },
  {
    roles: [1, 99],
    name: 'Criar Usuario',
    key: 'users-create',
    route: '/usuarios/criar',
    component: <UsersCreate />,
  },
  {
    roles: [1, 99],
    name: 'Editar Usuario',
    key: 'users-edit',
    route: '/usuarios/editar/:uuid',
    component: <UsersEdit />,
  },
  //region therapists
  {
    roles: [1],
    type: 'collapse',
    noCollapse: true,
    name: 'Terapeutas',
    key: 'therapists',
    route: '/terapeutas',
    icon: <Icon fontSize='medium'>people</Icon>,
    component: <Users />,
  },
  // {
  //   roles: [1],
  //   name: 'Visualizar terapeuta',
  //   key: 'therapists-view',
  //   route: '/terapeutas/:type_view/:uuid',
  //   component: <TherapistsView />,
  // },

  //region treatment
  {
    roles: [1, 2],
    type: 'collapse',
    noCollapse: true,
    name: 'Atendimentos',
    key: 'treatment',
    route: '/atendimentos',
    icon: <Icon fontSize='medium'>phone_in_talk</Icon>,
    component: <Treatments />,
  },

  {
    roles: [1, 99],
    type: 'collapse',
    noCollapse: true,
    name: 'Pacientes',
    key: 'pacients',
    route: '/pacientes',
    icon: <Icon fontSize='medium'>person</Icon>,
    component: <Patient />,
  },

  // {
  //   roles: [1],
  //   type: "collapse",
  //   noCollapse: true,
  //   name: "Pasta de atendimentos",
  //   key: "attendance-folder",
  //   route: "/pasta-de-atendimentos",
  //   icon: <Icon fontSize="medium">folder</Icon>,
  //   component: <AttendanceFolder />,
  // },

  {
    roles: [1, 2],
    type: 'collapse',
    noCollapse: true,
    name: 'Calendário',
    key: 'calendar',
    route: '/calendar',
    icon: <Icon fontSize='medium'>calendar_month</Icon>,
    component: <Calendar />,
  },

  // patients crud
  {
    roles: [1, 99],
    name: 'Criar Paciente',
    key: 'patient-create',
    route: '/pacientes/criar',
    component: <PatientsCreate />,
  },

  {
    roles: [1, 99],
    name: 'Criar Paciente',
    key: 'patient-edit',
    route: '/pacientes/editar/:uuid/:type_view',
    component: <PatientsEdit />,
  },

  {
    roles: [99],
    type: 'collapse',
    noCollapse: true,
    name: 'Critérios da avaliação',
    key: 'evaluation-criteria',
    route: '/criterios',
    icon: <Icon fontSize='medium'>gavel</Icon>,
    component: <EvaluationCriteria />,
  },

  {
    roles: [99],
    name: 'Criar Critério',
    key: 'evaluation-criteria-create',
    route: '/criterios/criar',
    component: <CriterionCreate />,
  },

  {
    roles: [99],
    name: 'Editar Critério',
    key: 'evaluation-criteria-edit',
    route: '/criterios/editar/:uuid',
    component: <CriterionEdit />,
  },

  // Logout
  {
    type: 'logout',
    noCollapse: true,
    icon: <Icon fontSize='medium'>logout</Icon>,
    name: 'Sair',
    key: 'logout',
  },

  // attendance folder
  // {
  //   roles: [1],
  //   name: 'Pasta de atendimentos',
  //   key: 'attendance-folder-behavior',
  //   route: '/pasta-de-atendimentos/comportamentos/:uuid',
  //   component: <Behaviors />,
  // },

  // {
  //   roles: [1],
  //   name: 'Comportamentos',
  //   key: 'attendance-folder-behavior-create',
  //   route: '/pasta-de-atendimentos/comportamentos/:uuid/create',
  //   component: <BehaviorsCreate />,
  // },
  // {
  //   roles: [1],
  //   name: 'Comportamentos',
  //   key: 'attendance-folder-behavior-edit',
  //   route:
  //     '/pasta-de-atendimentos/comportamentos/:uuid/BehaviorsEdit/:behavior_uuid',
  //   component: <BehaviorsEdit />,
  // },

  // {
  //   roles: [1],
  //   name: 'Pasta de atendimentos',
  //   key: 'attendance-folder-program',
  //   route: '/pasta-de-atendimentos/programas/:uuid',
  //   component: <Programs />,
  // },
  // {
  //   roles: [1],
  //   name: 'Comportamentos',
  //   key: 'attendance-folder-program-create',
  //   route: '/pasta-de-atendimentos/programas/:uuid/create',
  //   component: <ProgramsCreate />,
  // },
  // {
  //   roles: [1],
  //   name: 'Comportamentos',
  //   key: 'attendance-folder-program-edit',
  //   route: '/pasta-de-atendimentos/programas/:uuid/ProgramsEdit/:progam_uuid',
  //   component: <ProgramsEdit />,
  // },

  // therapists crud
  // {
  //   roles: [1, 99],
  //   name: "Calendario",
  //   key: "therapists-calendar",
  //   route: "/terapeutas/calendar",
  //   component: <CalendarTherapists />,
  // },

  // {
  //   roles: [1, 99],
  //   name: "Criar Paciente",
  //   key: "therapists-create",
  //   route: "/terapeutas/criar",
  //   component: <TherapistsCreate />,
  // },

  // {
  //   roles: [1, 99],
  //   name: "Criar Paciente",
  //   key: "therapists-create",
  //   route: "/terapeutas/editar/:uuid",
  //   component: <TherapistsEdit />,
  // },

  // {
  //   type: 'collapse',
  //   name: 'Brooklyn Alice',
  //   key: 'brooklyn-alice',
  //   icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
  //   collapse: [
  //     {
  //       name: 'My Profile',
  //       key: 'my-profile',
  //       route: '/pages/profile/profile-overview',
  //       component: <ProfileOverview />,
  //     },
  //     {
  //       name: 'Settings',
  //       key: 'profile-settings',
  //       route: '/pages/account/settings',
  //       component: <Settings />,
  //     },
  //     {
  //       name: 'Logout',
  //       key: 'logout',
  //       route: '/authentication/sign-in/basic',
  //       component: <SignInBasic />,
  //     },
  //   ],
  // },
  // { type: 'divider', key: 'divider-0' },
  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   collapse: [
  //     {
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/dashboards/analytics",
  //       component: <Analytics />,
  //     },
  //     {
  //       name: "Sales",
  //       key: "sales",
  //       route: "/dashboards/sales",
  //       component: <Sales />,
  //     },
  //   ],
  // },
  // { type: 'title', title: 'Pages', key: 'title-pages' },
  // {
  //   type: 'collapse',
  //   name: 'Pages',
  //   key: 'pages',
  //   icon: <Icon fontSize="medium">image</Icon>,
  //   collapse: [
  //     {
  //       name: 'Profile',
  //       key: 'profile',
  //       collapse: [
  //         {
  //           name: 'Profile Overview',
  //           key: 'profile-overview',
  //           route: '/pages/profile/profile-overview',
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: 'All Projects',
  //           key: 'all-projects',
  //           route: '/pages/profile/all-projects',
  //           component: <AllProjects />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Users',
  //       key: 'users',
  //       collapse: [
  //         {
  //           name: 'New User',
  //           key: 'new-user',
  //           route: '/pages/users/new-user',
  //           component: <NewUser />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Account',
  //       key: 'account',
  //       collapse: [
  //         {
  //           name: 'Settings',
  //           key: 'settings',
  //           route: '/pages/account/settings',
  //           component: <Settings />,
  //         },
  //         {
  //           name: 'Billing',
  //           key: 'billing',
  //           route: '/pages/account/billing',
  //           component: <Billing />,
  //         },
  //         {
  //           name: 'Invoice',
  //           key: 'invoice',
  //           route: '/pages/account/invoice',
  //           component: <Invoice />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Projects',
  //       key: 'projects',
  //       collapse: [
  //         {
  //           name: 'Timeline',
  //           key: 'timeline',
  //           route: '/pages/projects/timeline',
  //           component: <Timeline />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Pricing Page',
  //       key: 'pricing-page',
  //       route: '/pages/pricing-page',
  //       component: <PricingPage />,
  //     },
  //     { name: 'RTL', key: 'rtl', route: '/pages/rtl', component: <RTL /> },
  //     { name: 'Widgets', key: 'widgets', route: '/pages/widgets', component: <Widgets /> },
  //     { name: 'Charts', key: 'charts', route: '/pages/charts', component: <Charts /> },
  //     {
  //       name: 'Notfications',
  //       key: 'notifications',
  //       route: '/pages/notifications',
  //       component: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   type: 'collapse',
  //   name: 'Applications',
  //   key: 'applications',
  //   icon: <Icon fontSize="medium">apps</Icon>,
  //   collapse: [
  //     {
  //       name: 'Kanban',
  //       key: 'kanban',
  //       route: '/applications/kanban',
  //       component: <Kanban />,
  //     },
  //     {
  //       name: 'Wizard',
  //       key: 'wizard',
  //       route: '/applications/wizard',
  //       component: <Wizard />,
  //     },
  //     {
  //       name: 'Data Tables',
  //       key: 'data-tables',
  //       route: '/applications/data-tables',
  //       component: <DataTables />,
  //     },
  //     {
  //       name: 'Calendar',
  //       key: 'calendar',
  //       route: '/applications/calendar',
  //       component: <Calendar />,
  //     },
  //   ],
  // },
  // {
  //   type: 'collapse',
  //   name: 'Ecommerce',
  //   key: 'ecommerce',
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   collapse: [
  //     {
  //       name: 'Products',
  //       key: 'products',
  //       collapse: [
  //         {
  //           name: 'New Product',
  //           key: 'new-product',
  //           route: '/ecommerce/products/new-product',
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: 'Edit Product',
  //           key: 'edit-product',
  //           route: '/ecommerce/products/edit-product',
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: 'Product Page',
  //           key: 'product-page',
  //           route: '/ecommerce/products/product-page',
  //           component: <ProductPage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Orders',
  //       key: 'orders',
  //       collapse: [
  //         {
  //           name: 'Order List',
  //           key: 'order-list',
  //           route: '/ecommerce/orders/order-list',
  //           component: <OrderList />,
  //         },
  //         {
  //           name: 'Order Details',
  //           key: 'order-details',
  //           route: '/ecommerce/orders/order-details',
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: 'collapse',
  //   name: 'Authentication',
  //   key: 'authentication',
  //   icon: <Icon fontSize="medium">content_paste</Icon>,
  //   collapse: [
  //     {
  //       name: 'Sign In',
  //       key: 'sign-in',
  //       collapse: [
  //         {
  //           name: 'Basic',
  //           key: 'basic',
  //           route: '/authentication/sign-in/basic',
  //           component: <SignInBasic />,
  //         },
  //         {
  //           name: 'Cover',
  //           key: 'cover',
  //           route: '/authentication/sign-in/cover',
  //           component: <SignInCover />,
  //         },
  //         {
  //           name: 'Illustration',
  //           key: 'illustration',
  //           route: '/authentication/sign-in/illustration',
  //           component: <SignInIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Sign Up',
  //       key: 'sign-up',
  //       collapse: [
  //         {
  //           name: 'Cover',
  //           key: 'cover',
  //           route: '/authentication/sign-up/cover',
  //           component: <SignUpCover />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Reset Password',
  //       key: 'reset-password',
  //       collapse: [
  //         {
  //           name: 'Cover',
  //           key: 'cover',
  //           route: '/authentication/reset-password/cover',
  //           component: <ResetCover />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // { type: 'divider', key: 'divider-1' },
  // { type: 'title', title: 'Docs', key: 'title-docs' },
  // {
  //   type: 'collapse',
  //   name: 'Basic',
  //   key: 'basic',
  //   icon: <Icon fontSize="medium">upcoming</Icon>,
  //   collapse: [
  //     {
  //       name: 'Getting Started',
  //       key: 'getting-started',
  //       collapse: [
  //         {
  //           name: 'Overview',
  //           key: 'overview',
  //           href: 'https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/',
  //         },
  //         {
  //           name: 'License',
  //           key: 'license',
  //           href: 'https://www.creative-tim.com/learning-lab/react/license/material-dashboard/',
  //         },
  //         {
  //           name: 'Quick Start',
  //           key: 'quick-start',
  //           href: 'https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/',
  //         },
  //         {
  //           name: 'Build Tools',
  //           key: 'build-tools',
  //           href: 'https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/',
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Foundation',
  //       key: 'foundation',
  //       collapse: [
  //         {
  //           name: 'Colors',
  //           key: 'colors',
  //           href: 'https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/',
  //         },
  //         {
  //           name: 'Grid',
  //           key: 'grid',
  //           href: 'https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/',
  //         },
  //         {
  //           name: 'Typography',
  //           key: 'base-typography',
  //           href: 'https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/',
  //         },
  //         {
  //           name: 'Borders',
  //           key: 'borders',
  //           href: 'https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/',
  //         },
  //         {
  //           name: 'Box Shadows',
  //           key: 'box-shadows',
  //           href: 'https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/',
  //         },
  //         {
  //           name: 'Functions',
  //           key: 'functions',
  //           href: 'https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/',
  //         },
  //         {
  //           name: 'Routing System',
  //           key: 'routing-system',
  //           href: 'https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: 'collapse',
  //   name: 'Components',
  //   key: 'components',
  //   icon: <Icon fontSize="medium">view_in_ar</Icon>,
  //   collapse: [
  //     {
  //       name: 'Alerts',
  //       key: 'alerts',
  //       href: 'https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/',
  //     },
  //     {
  //       name: 'Avatar',
  //       key: 'avatar',
  //       href: 'https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/',
  //     },
  //     {
  //       name: 'Badge',
  //       key: 'badge',
  //       href: 'https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/',
  //     },
  //     {
  //       name: 'Badge Dot',
  //       key: 'badge-dot',
  //       href: 'https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/',
  //     },
  //     {
  //       name: 'Box',
  //       key: 'box',
  //       href: 'https://www.creative-tim.com/learning-lab/react/box/material-dashboard/',
  //     },
  //     {
  //       name: 'Buttons',
  //       key: 'buttons',
  //       href: 'https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/',
  //     },
  //     {
  //       name: 'Date Picker',
  //       key: 'date-picker',
  //       href: 'https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/',
  //     },
  //     {
  //       name: 'Dropzone',
  //       key: 'dropzone',
  //       href: 'https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/',
  //     },
  //     {
  //       name: 'Editor',
  //       key: 'editor',
  //       href: 'https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/',
  //     },
  //     {
  //       name: 'Input',
  //       key: 'input',
  //       href: 'https://www.creative-tim.com/learning-lab/react/input/material-dashboard/',
  //     },
  //     {
  //       name: 'Pagination',
  //       key: 'pagination',
  //       href: 'https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/',
  //     },
  //     {
  //       name: 'Progress',
  //       key: 'progress',
  //       href: 'https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/',
  //     },
  //     {
  //       name: 'Snackbar',
  //       key: 'snackbar',
  //       href: 'https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/',
  //     },
  //     {
  //       name: 'Social Button',
  //       key: 'social-button',
  //       href: 'https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/',
  //     },
  //     {
  //       name: 'Typography',
  //       key: 'typography',
  //       href: 'https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/',
  //     },
  //   ],
  // },
  // {
  //   type: 'collapse',
  //   name: 'Change Log',
  //   key: 'changelog',
  //   href: 'https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md',
  //   icon: <Icon fontSize="medium">receipt_long</Icon>,
  //   noCollapse: true,
  // },
];

export default routes;
