import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import MDBox from '../../MDBox';
import { useTheme } from '@mui/material';
import GraphLegends from '../legends';
import {
  createDataGroupMultiline,
  findLargestArraySize,
  generateNullArray,
  generateNumberArray,
} from '../chartFunctions';
import PropTypes from 'prop-types';
import customLabelsPlugin from './customLabelsPlugin';
import { createAnnotations } from './functionsSectorizedGraph';

// Registrar os componentes que serão utilizados do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const SectorizedMultipleLineGraph = ({
  graphData,
  chartRef,
  chartContainerRef,
  attempsNumber,
}) => {
  const theme = useTheme();
  const { palette } = theme;
  const [buttonEnabled, setButtonEnabled] = useState({
    y1: false,
    y2: false,
    y3: false,
  });
  const [dataGraph, setDataGraph] = useState({ labels: [], datasets: [] });

  const { baseline, interventionWHelp, interventionIndependent, maintenance } =
    graphData;

  const dataGroups = {
    baseline: createDataGroupMultiline(baseline),
    interventionWHelp: createDataGroupMultiline(interventionWHelp),
    interventionIndependent: createDataGroupMultiline(interventionIndependent),
    maintenance: createDataGroupMultiline(maintenance),
  };

  const { corrects: dataBaseLineCorrects, incorrects: dataBaseLineIncorrects } =
    dataGroups.baseline;
  const {
    corrects: dataInterventionWHelpCorrects,
    incorrects: dataInterventionWHelpIncorrects,
    corrects_help: dataInterventionWHelpCorrectsWHelp,
  } = dataGroups.interventionWHelp;
  const {
    corrects: dataInterventionIndependentCorrects,
    incorrects: dataInterventionIndependentIncorrects,
  } = dataGroups.interventionIndependent;
  const {
    corrects: dataMaintenanceCorrects,
    incorrects: dataMaintenanceIncorrects,
  } = dataGroups.maintenance;

  // dataBaseLineCorrects.unshift(null);
  // dataBaseLineIncorrects.unshift(null);

  //baseline
  const formatDataBaseLineCorrects = [
    ...dataBaseLineCorrects,
    ...generateNullArray(
      dataInterventionWHelpCorrects.length +
        dataInterventionIndependentCorrects.length +
        dataMaintenanceCorrects.length
    ),
  ];
  const formatDataBaseLineIncorrects = [
    ...dataBaseLineIncorrects,
    ...generateNullArray(
      dataInterventionWHelpIncorrects.length +
        dataInterventionIndependentIncorrects.length +
        dataMaintenanceIncorrects.length
    ),
  ];
  //Intervention With Help
  const formatDataInterventionWithHelpCorrects = [
    ...generateNullArray(
      findLargestArraySize(dataBaseLineIncorrects, dataBaseLineCorrects)
    ),
    ...dataInterventionWHelpCorrects,
    ...generateNullArray(
      dataInterventionIndependentCorrects.length +
        dataMaintenanceCorrects.length
    ),
  ];
  const formatDataInterventionWithHelpIncorrects = [
    ...generateNullArray(
      findLargestArraySize(dataBaseLineIncorrects, dataBaseLineCorrects)
    ),
    ...dataInterventionWHelpIncorrects,
    ...generateNullArray(
      dataInterventionIndependentIncorrects.length +
        dataMaintenanceIncorrects.length
    ),
  ];
  const formatDataInterventionWithHelpCorrectsWHelp = [
    ...generateNullArray(
      findLargestArraySize(dataBaseLineIncorrects, dataBaseLineCorrects)
    ),
    ...dataInterventionWHelpCorrectsWHelp,
    ...generateNullArray(
      dataInterventionIndependentCorrects.length +
        dataMaintenanceCorrects.length
    ),
  ];
  //Intervention Independent
  const formatDataInterventionIndependentCorrects = [
    ...generateNullArray(
      findLargestArraySize(dataBaseLineIncorrects, dataBaseLineCorrects) +
        findLargestArraySize(
          dataInterventionWHelpCorrectsWHelp,
          dataInterventionWHelpIncorrects,
          dataInterventionWHelpCorrects
        )
    ),
    ...dataInterventionIndependentCorrects,
    ...generateNullArray(dataMaintenanceCorrects.length),
  ];
  const formatDataInterventionIndependentIncorrects = [
    ...generateNullArray(
      findLargestArraySize(dataBaseLineIncorrects, dataBaseLineCorrects) +
        findLargestArraySize(
          dataInterventionWHelpCorrectsWHelp,
          dataInterventionWHelpIncorrects,
          dataInterventionWHelpCorrects
        )
    ),
    ...dataInterventionIndependentIncorrects,
    ...generateNullArray(dataMaintenanceIncorrects.length),
  ];
  //Maintenance
  const formatDataMaintenanceCorrects = [
    ...generateNullArray(
      findLargestArraySize(dataBaseLineIncorrects, dataBaseLineCorrects) +
        findLargestArraySize(
          dataInterventionWHelpCorrectsWHelp,
          dataInterventionWHelpCorrects,
          dataInterventionWHelpIncorrects
        ) +
        dataInterventionIndependentCorrects.length
    ),
    ...dataMaintenanceCorrects,
  ];
  const formatDataMaintenanceIncorrect = [
    ...generateNullArray(
      findLargestArraySize(dataBaseLineIncorrects, dataBaseLineCorrects) +
        findLargestArraySize(
          dataInterventionWHelpCorrectsWHelp,
          dataInterventionWHelpIncorrects,
          dataInterventionWHelpCorrects
        ) +
        dataInterventionIndependentIncorrects.length
    ),
    ...dataMaintenanceIncorrects,
  ];

  // axis X length
  const labelNumbersArray = useMemo(
    () =>
      generateNumberArray(
        findLargestArraySize(dataBaseLineCorrects, dataBaseLineIncorrects) +
          findLargestArraySize(
            dataInterventionWHelpCorrects,
            dataInterventionWHelpIncorrects,
            dataInterventionWHelpCorrectsWHelp
          ) +
          findLargestArraySize(
            dataInterventionIndependentCorrects,
            dataInterventionIndependentIncorrects
          ) +
          findLargestArraySize(
            dataMaintenanceCorrects,
            dataMaintenanceIncorrects
          ),
        1
      ),
    [
      dataBaseLineCorrects,
      dataBaseLineIncorrects,
      dataInterventionWHelpCorrects,
      dataInterventionWHelpIncorrects,
      dataInterventionWHelpCorrectsWHelp,
      dataInterventionIndependentCorrects,
      dataInterventionIndependentIncorrects,
      dataMaintenanceCorrects,
      dataMaintenanceIncorrects,
    ]
  );

  // posições das barras
  const divisionAPosition = findLargestArraySize(
    dataBaseLineCorrects,
    dataBaseLineIncorrects
  );
  const divisionBPosition =
    divisionAPosition +
    findLargestArraySize(
      dataInterventionWHelpCorrects,
      dataInterventionWHelpIncorrects,
      dataInterventionWHelpCorrectsWHelp
    );
  const divisionCPosition =
    divisionBPosition +
    findLargestArraySize(
      dataInterventionIndependentCorrects,
      dataInterventionIndependentIncorrects
    );

  // Dados para o gráfico
  const data = useMemo(() => {
    const createDataset = (data, label, borderColor, yAxisID) => {
      if (data.length === 0) return null;
      return {
        data,
        label,
        borderColor,
        borderWidth: 2,
        fill: false,
        yAxisID,
      };
    };

    const validDatasets = [];

    const hasValidData = (data) => data && data.some((item) => item !== null);

    if (hasValidData(formatDataBaseLineIncorrects)) {
      validDatasets.push(
        createDataset(
          formatDataBaseLineIncorrects,
          'erros baseline',
          palette['error'].main,
          'y1'
        )
      );
    }

    if (hasValidData(formatDataBaseLineCorrects)) {
      validDatasets.push(
        createDataset(
          formatDataBaseLineCorrects,
          'acertos baseline',
          palette['success'].main,
          'y2'
        )
      );
    }

    // Intervention With Help
    if (hasValidData(formatDataInterventionWithHelpIncorrects)) {
      validDatasets.push(
        createDataset(
          formatDataInterventionWithHelpIncorrects,
          'erros intervenção com ajuda',
          palette['error'].main,
          'y1'
        )
      );
    }

    if (hasValidData(formatDataInterventionWithHelpCorrects)) {
      validDatasets.push(
        createDataset(
          formatDataInterventionWithHelpCorrects,
          'acertos intervenção com ajuda',
          palette['success'].main,
          'y2'
        )
      );
    }

    if (hasValidData(formatDataInterventionWithHelpCorrectsWHelp)) {
      validDatasets.push(
        createDataset(
          formatDataInterventionWithHelpCorrectsWHelp,
          'acertos com ajuda intervenção com ajuda',
          palette['ternary'].main,
          'y3'
        )
      );
    }

    // Intervention Independent
    if (hasValidData(formatDataInterventionIndependentIncorrects)) {
      validDatasets.push(
        createDataset(
          formatDataInterventionIndependentIncorrects,
          'erros intervenção independente',
          palette['error'].main,
          'y1'
        )
      );
    }

    if (hasValidData(formatDataInterventionIndependentCorrects)) {
      validDatasets.push(
        createDataset(
          formatDataInterventionIndependentCorrects,
          'acertos intervenção independente',
          palette['success'].main,
          'y2'
        )
      );
    }

    // Maintenance
    if (hasValidData(formatDataMaintenanceIncorrect)) {
      validDatasets.push(
        createDataset(
          formatDataMaintenanceIncorrect,
          'erros manutenção',
          palette['error'].main,
          'y1'
        )
      );
    }

    if (hasValidData(formatDataMaintenanceCorrects)) {
      validDatasets.push(
        createDataset(
          formatDataMaintenanceCorrects,
          'acertos manutenção',
          palette['success'].main,
          'y2'
        )
      );
    }

    const filteredDatasets = validDatasets.filter(Boolean);
    return {
      labels: labelNumbersArray,
      datasets: filteredDatasets,
    };
  }, [graphData]);

  const annotationLines = createAnnotations(
    divisionAPosition,
    divisionBPosition,
    divisionCPosition
  );

  // Configurações para o gráfico
  const options = useMemo(
    () => ({
      scales: {
        y1: {
          beginAtZero: true,
          max: attempsNumber,
          ticks: {
            stepSize: 1,
            callback: (value) => value.toFixed(0),
          },
          title: {
            display: true,
            text: 'n° de acertos',
          },
        },
        y2: {
          beginAtZero: true,
          max: attempsNumber,
          display: false,
          ticks: {
            stepSize: 1,
            callback: (value) => value.toFixed(0),
          },
          title: {
            display: false,
            text: 'n° de acertos',
          },
        },
        y3: {
          beginAtZero: true,
          max: attempsNumber,
          display: false,
          ticks: {
            stepSize: 1,
            callback: (value) => value.toFixed(0),
          },
          title: {
            display: false,
            text: 'n° de acertos',
          },
        },
        x: {
          min: 1,
          title: {
            display: true,
            text: 'Sessões',
          },
        },
      },
      plugins: {
        annotation: {
          annotations: annotationLines,
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (context) => {
              const value = context.raw;
              return `Valor: ${value}`;
            },
            title: (context) => {
              return `Sessão ${context[0].label}`;
            },
          },
          backgroundColor: '#ffffff',
          titleColor: '#000000',
          bodyColor: '#000000',
          borderColor: '#cccccc',
          borderWidth: 1,
        },
      },
      layout: {
        padding: {
          top: 40,
        },
      },
    }),
    [graphData]
  );

  const toggleAnswers = (yAxisID) => {
    if (!chartRef.current) return;

    const chart = chartRef.current;
    const datasets = chart.data.datasets;

    datasets.forEach((dataset, index) => {
      if (dataset.yAxisID === yAxisID) {
        const isVisible = chart.isDatasetVisible(index);
        if (isVisible) {
          chart.hide(index);
        } else {
          chart.show(index);
        }
      }
    });

    chart.update();
  };

  const verifyIfHasYAxisID = (yAxisID) => {
    if (!chartRef.current) return false;

    const chart = chartRef.current;
    const datasets = chart.data.datasets;

    const hasMatchingYAxis = datasets.some(
      (dataset) =>
        dataset.yAxisID === yAxisID &&
        dataset.data.some((value) => value !== null)
    );

    setButtonEnabled((prev) => ({
      ...prev,
      [yAxisID]: hasMatchingYAxis,
    }));
  };

  useEffect(() => {
    chartRef?.current?.update();

    if (graphData) {
      setDataGraph(data);
    }
  }, [graphData, data, options, chartRef]);

  useEffect(() => {
    if (dataGraph.datasets.length > 0) {
      verifyIfHasYAxisID('y1');
      verifyIfHasYAxisID('y2');
      verifyIfHasYAxisID('y3');
    }
  }, [dataGraph]);

  return (
    <MDBox ref={chartContainerRef}>
      <Line
        ref={chartRef}
        data={dataGraph}
        options={options}
        plugins={[customLabelsPlugin()]}
      />

      <MDBox id='legend' display='flex' justifyContent='center' gap={2}>
        {buttonEnabled.y2 && (
          <GraphLegends
            label='Corretas'
            color='success'
            onClick={() => toggleAnswers('y2')}
          />
        )}
        {buttonEnabled.y1 && (
          <GraphLegends
            label='Incorretas'
            color='error'
            onClick={() => toggleAnswers('y1')}
          />
        )}
        {buttonEnabled.y3 && (
          <GraphLegends
            label='Corretas com ajuda'
            color='ternary'
            onClick={() => toggleAnswers('y3')}
          />
        )}
      </MDBox>
    </MDBox>
  );
};

SectorizedMultipleLineGraph.propTypes = {
  graphData: PropTypes.any.isRequired,
  chartRef: PropTypes.any.isRequired,
  chartContainerRef: PropTypes.any.isRequired,
  attempsNumber: PropTypes.number.isRequired,
};

export default React.memo(SectorizedMultipleLineGraph);
