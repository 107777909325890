import { isAxiosError } from "axios";
import { FILE_PATIENT_API, FILE_PATIENTS_API } from "./file_patients";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";

class FilePatientsService {
  create = async (uuid, formData, setLoadingValue) => {
    try {
      const response = await customAxios.post(
        `${FILE_PATIENT_API}/patient/${uuid}`,
        formData,
        {
          onUploadProgress: (event) => {
            const progress = Math.round((event.loaded * 100) / event.total);
            setLoadingValue(progress - 1);
          },
        }
      );
      setLoadingValue(100);

      if (!response) new Error("Não foi possível criar arquivo");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível criar arquivo");
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${FILE_PATIENT_API}/${uuid}`);

      if (!response) new Error("Não foi possível carregar arquivo");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível carregar arquivo");
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = "") => {
    try {
      const response = await customAxios.get(`${FILE_PATIENTS_API}${filter}`);

      if (!response) new Error("Não foi possível listar arquivos");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível listar arquivos");
        }
      }

      throw new Error(error);
    }
  };

  edit = async (uuid, formData) => {
    try {
      const response = await customAxios.put(
        `${FILE_PATIENT_API}/${uuid}`,
        formData
      );

      if (!response) new Error("Não foi possível editar arquivo");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível editar arquivo");
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${FILE_PATIENT_API}/${uuid}`);

      if (!response) new Error("Não foi possível apagar arquivo");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível apagar arquivo");
        }
      }

      throw new Error(error);
    }
  };

  getFile = async (uuid) => {
    try {
      const response = await customAxios
        .get(`${FILE_PATIENT_API}/${uuid}`)
        .then(async (res) => {
          const link = document.createElement("a");
          link.href = res.data;
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        });
      return response.data;
    } catch (e) {
      throw new Error("Não foi possível fazer download de arquivo");
    }
  };
}

export default new FilePatientsService();
