/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Form, Formik } from "formik";

import {
  Autocomplete,
  // Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Icon,
  IconButton,
  Slide,
  // createFilterOptions,
} from "@mui/material";

import dialog from "assets/theme/components/dialog";
import dialogTitle from "assets/theme/components/dialog/dialogTitle";
import dialogContent from "assets/theme/components/dialog/dialogContent";

import MDButton from "shared/components/MDButton";

import {
  handleValue,
  setValue,
  renderInput,
  compareOptionToValue,
} from "shared/components/autocomplete/autocompleteConfigs";

import { initialValuesAppointments } from "../schemas/initial_values";
import { validationsEdit } from "../schemas/validations";

// import { recurrenceOptions } from "utils/essentialData";
import FormField from "shared/components/form_field";
import MDBox from "shared/components/MDBox";
import {
  userLogged,
  // translateRecurrenceOptions
} from "utils/essentialData";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import toastError from "shared/components/snackbar/error/toastError";
import calendarService from "shared/services/calendar/calendar.service";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
export const dialogButton = {
  width: "115px",
};

function CreateAppointment({
  open,
  setOpen,
  onClose,
  patientOptions,
  therapistOptions,
  setOpenDel,
}) {
  const [titleCard, setTitleCard] = useState("");
  const [supervisorName, setSupervisorName] = useState("");
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();
  const userRole = userLogged()?.role;

  // const filterOptionsRecurrence = createFilterOptions({
  //   stringify: translateRecurrenceOptions,
  // });

  const dateOptions = {
    // weekday: 'long',
    year: "numeric",
    month: "long",
    day: "numeric",
    timezone: "UTC",
  };

  const formatTitleCard = (value) => {
    const days = [
      "domingo",
      "segunda-feira",
      "terça-feira",
      "quarta-feira",
      "quinta-feira",
      "sexta-feira",
      "sábado",
    ];

    const startDate = value.startStr ? new Date(value.startStr) : null;
    const weekDay = startDate ? days[startDate.getDay()] : null;
    const formatedDate = startDate
      ? startDate.toLocaleDateString("pt-BR", dateOptions)
      : null;
    setTitleCard(`${weekDay}, ${formatedDate}`);
  };

  const handleSubmit = async (values, actions) => {
    let stringTitle = "";

    if (values?.therapist_uuid?.name)
      stringTitle += values?.therapist_uuid?.name;
    if (values?.patient_uuid?.name)
      stringTitle += ` - ${values?.patient_uuid?.name}`;

    const data = {
      ...values,
      title: stringTitle,
      therapist_uuid: values?.therapist_uuid?.uuid,
      patient_uuid: values?.patient_uuid?.uuid,
    };

    try {
      await calendarService.edit(values.uuid, data);
      toastSuccess("Agenda editada com sucesso");
      actions.setTouched({});
      actions.setSubmitting(false);
      onClose();
    } catch (e) {
      toastError(e.message);
    }
  };

  const disableField = (fieldName) => {
    if (userRole === 2) return true;
    if (fieldName === "date") return true;
    return false;
  };

  const handleFindItemList = (array, value, key = "uuid") =>
    array.find((val) => val[key] === value) || "";

  useEffect(() => {
    if (open.data && open.open) {
      formatTitleCard(open.data);
      setSupervisorName(open.data?.extendedProps?.supervisor_name);
      setInitialValuesFromApi({
        uuid: open?.data?.extendedProps?.uuid,
        date: open.data?.extendedProps?.start_date,
        start_hour: open.data?.extendedProps?.start_hour,
        end_hour: open.data?.extendedProps?.end_hour,
        therapist_uuid: handleFindItemList(
          therapistOptions?.data,
          open.data?.extendedProps?.owner
        ),
        patient_uuid: handleFindItemList(
          patientOptions?.data,
          open.data?.extendedProps?.patient_uuid
        ),
      });
    }
  }, [open]);

  const filterActiveUserPatient = (array) =>
    array.filter((v) => v.status === 1);

  return (
    <Dialog
      open={open.open}
      // setOpen={setOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ ...dialog, textAlign: "center" }}
      fullWidth
      maxWidth="sm"
    >
      {/* <DialogTitle sx={dialogTitle}> {titleCard} </DialogTitle> */}
      <DialogContent sx={dialogContent}>
        <MDBox position="relative">
          <MDBox sx={{ ...dialogTitle, fontWeight: "bold" }}>{titleCard}</MDBox>
          <DialogContentText>{supervisorName}</DialogContentText>
          {userRole !== 2 && (
            <MDBox position="absolute" right={0} top={0}>
              <IconButton
                onClick={() => {
                  setOpenDel({ ...open, open: true });
                  // setOpen({ ...open, open: false });
                }}
              >
                <Icon fontSize="large" color="error">
                  delete
                </Icon>
              </IconButton>
            </MDBox>
          )}
        </MDBox>
        <Formik
          initialValues={initialValuesFromApi ?? initialValuesAppointments}
          validationSchema={validationsEdit[0]}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleBlur,
          }) => {
            const {
              date: dateV,
              start_hour: start_hourV,
              end_hour: end_hourV,
              therapist_uuid: therapist_uuidV,
              patient_uuid: patient_uuidV,
              // recurrence: recurrenceV,
            } = values;
            return (
              <Form
                id="user-create-form"
                autoComplete="off"
                style={{ overflow: "hidden", margin: "16px" }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      name="date"
                      disabled={disableField("date")}
                      label="Data *"
                      type="date"
                      fieldShrink
                      value={dateV}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="start_hour"
                      disabled={disableField("start_hour")}
                      label="Hora inicial *"
                      type="time"
                      fieldShrink
                      value={start_hourV}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormField
                      name="end_hour"
                      disabled={disableField("end_hour")}
                      label="Hora final"
                      type="time"
                      fieldShrink
                      value={end_hourV}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      disabled={disableField("therapist_uuid")}
                      options={
                        therapistOptions?.data
                          ? filterActiveUserPatient(therapistOptions?.data)
                          : []
                      }
                      freeSolo
                      getOptionLabel={(opt) => opt.name}
                      value={handleValue(therapist_uuidV)}
                      isOptionEqualToValue={compareOptionToValue}
                      onChange={(e, value) =>
                        setValue(setFieldValue, "therapist_uuid", value)
                      }
                      renderInput={(params) =>
                        renderInput(
                          "form",
                          params,
                          "therapist_uuid",
                          "Terapeutas *",
                          handleBlur
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      disabled={disableField("patient_uuid")}
                      options={
                        patientOptions?.data
                          ? filterActiveUserPatient(patientOptions?.data)
                          : []
                      }
                      freeSolo
                      getOptionLabel={(opt) => opt.name}
                      value={handleValue(patient_uuidV)}
                      isOptionEqualToValue={compareOptionToValue}
                      onChange={(e, value) =>
                        setValue(setFieldValue, "patient_uuid", value)
                      }
                      renderInput={(params) =>
                        renderInput(
                          "form",
                          params,
                          "patient_uuid",
                          "Pacientes",
                          handleBlur
                        )
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} mt={1}>
                  <Grid item xs={6} sm={userRole !== 2 ? 6 : 12}>
                    <MDButton
                      color="dark"
                      onClick={() => setOpen({ ...open, open: false })}
                      style={dialogButton}
                      key="cancel"
                    >
                      cancelar
                    </MDButton>
                  </Grid>
                  {userRole !== 2 && (
                    <Grid item xs={6} sm={6}>
                      <MDButton
                        disabled={isSubmitting}
                        color="primary"
                        type="submit"
                        style={dialogButton}
                        fullWidth
                      >
                        SALVAR
                      </MDButton>
                    </Grid>
                  )}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

CreateAppointment.defaultProps = {
  onClose: null,
  patientOptions: {},
  therapistOptions: {},
  setOpenDel: null,
};

CreateAppointment.propTypes = {
  open: PropTypes.objectOf(PropTypes.any).isRequired,
  setOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  patientOptions: PropTypes.objectOf(PropTypes.any),
  therapistOptions: PropTypes.objectOf(PropTypes.any),
  setOpenDel: PropTypes.func,
};

export default CreateAppointment;
