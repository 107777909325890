import { Card, Icon } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import SectorizedMultipleLineGraph from 'shared/components/charts/SectorizedMultipleLineGraph/sectorizedMultipleLineGraph';
import MDBox from 'shared/components/MDBox';
import MDButton from 'shared/components/MDButton';
import MDTypography from 'shared/components/MDTypography';
import ModalFilterGraph from 'shared/components/modals/modalFilterGraph/modalFilterGraph';
import toastError from 'shared/components/snackbar/error/toastError';
import { downloadGraph } from 'utils/pdfFunctions';
import ProgramsService from 'shared/services/programs/programs.service';
import { useDateFilter } from 'context/DateFilterContext';
import { parseDateFromApi } from 'utils/essentialData';

const initialModalValues = {
  initial_date: '',
  final_date: '',
  type_date: 'all',
};

function GraphProgramPerformance({ itemData, setStep }) {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterSearch, setFilterSearch] = useState();
  const [initialFilterValue, setInitialFilterValue] =
    useState(initialModalValues);

  const { setFirstGraphDate } = useDateFilter();

  const [graphData, setGraphData] = useState();
  const [attempsNumber, setAttempsNumber] = useState(0);

  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);

  const loadGraph = async (filter = '') => {
    try {
      setAttempsNumber(itemData?.attemps_number);
      const res = await ProgramsService.getGraphData(itemData.uuid, filter);

      if (res.first_session_date && !filter) {
        const today = new Date();
        const firstSessionDate = parseDateFromApi(res.first_session_date);
        setFirstGraphDate(firstSessionDate);
        setInitialFilterValue((prevState) => ({
          ...prevState,
          initial_date: firstSessionDate,
          final_date: parseDateFromApi(today.toISOString()),
          type_date: 'all',
        }));
      }

      setGraphData(res);

      // teste
      // setAttempsNumber(9);
      // setGraphData({
      //   baseline: {
      //     corrects: [3, 4],
      //     incorrects: [2, 1],
      //   },
      //   interventionWHelp: {
      //     corrects: [2, 1],
      //     corrects_help: [3, 2],
      //     incorrects: [6, 8],
      //   },
      //   interventionIndependent: {
      //     corrects: [2, 1],
      //     incorrects: [8, 9],
      //   },
      //   maintenance: {
      //     corrects: [4, 5, 4, 5],
      //     incorrects: [6, 5, 6, 5],
      //   },
      // });
    } catch (error) {
      toastError(error.message);
    }
  };

  const downloadGraphPdf = () =>
    downloadGraph(chartContainerRef, itemData.name);

  const formatFilter = (filterSearch) => {
    const { type_date, initial_date, final_date } = filterSearch;
    let filterString = '';

    if (type_date === 'all') return '';
    if (initial_date) {
      filterString += `startDate=${initial_date}`;
    }
    if (final_date) {
      filterString += filterString
        ? `&endDate=${final_date}`
        : `endDate=${final_date}`;
    }
    return filterString ? `?${filterString}` : '';
  };

  useEffect(() => {
    if (filterSearch) {
      const filter = formatFilter(filterSearch);
      loadGraph(filter);
    }
  }, [filterSearch]);

  useEffect(() => {
    if (itemData) {
      loadGraph();
    }
    return () => {
      setGraphData();
    };
  }, [itemData]);

  return (
    <>
      <Card sx={{ p: 3 }}>
        <MDBox display='flex' justifyContent='space-between'>
          <MDBox display='flex' gap={2}>
            <MDBox>
              <MDButton
                variant='outlined'
                color='primary'
                onClick={() => {
                  setStep('list');
                }}
                sx={{ height: 'fit-content' }}
              >
                Voltar
              </MDButton>
            </MDBox>
            <MDBox>
              <MDTypography variant='h4'>
                {itemData ? itemData.patient.name : ''}
              </MDTypography>
              <MDTypography variant='h5' sx={{ fontWeight: 400 }}>
                Programa: {itemData ? itemData.name : ''}
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox display='flex' gap={1}>
            <MDButton
              variant='outlined'
              color='primary'
              onClick={() => {
                setOpenFilter(true);
              }}
              sx={{ height: 'fit-content' }}
            >
              Filtrar
            </MDButton>
            <MDButton
              color='primary'
              onClick={() => downloadGraphPdf()}
              sx={{ height: 'fit-content' }}
            >
              Baixar
              <Icon>download</Icon>
            </MDButton>
          </MDBox>
        </MDBox>

        {graphData !== undefined && (
          <MDBox>
            <SectorizedMultipleLineGraph
              graphData={graphData}
              chartRef={chartRef}
              chartContainerRef={chartContainerRef}
              attempsNumber={attempsNumber}
            />
          </MDBox>
        )}
      </Card>

      <ModalFilterGraph
        open={openFilter}
        onClose={() => {
          setOpenFilter(false);
          setInitialFilterValue(filterSearch);
        }}
        setFilterValue={setFilterSearch}
        initialFilterValue={initialFilterValue}
      />
    </>
  );
}

GraphProgramPerformance.propTypes = {
  itemData: PropTypes.objectOf(PropTypes.any).isRequired,
  setStep: PropTypes.func,
};

export default GraphProgramPerformance;
