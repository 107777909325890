import React from 'react';

import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
} from '@mui/material';

import dialog from 'assets/theme/components/dialog';
import dialogTitle from 'assets/theme/components/dialog/dialogTitle';
import dialogContent from 'assets/theme/components/dialog/dialogContent';
import dialogContentText from 'assets/theme/components/dialog/dialogContentText';
import dialogActions from 'assets/theme/components/dialog/dialogActions';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));
export const dialogButton = {
  width: '115px',
};

function PopUp({
  open,
  onClose,
  title,
  contentText,
  contentComponent,
  actions,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ ...dialog, textAlign: 'center' }}
    >
      <DialogTitle sx={dialogTitle}>{title}</DialogTitle>
      <DialogContent sx={dialogContent}>
        <DialogContentText sx={dialogContentText}>
          {contentText}
        </DialogContentText>
        {contentComponent}
      </DialogContent>
      <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />
      <DialogActions sx={{ ...dialogActions, justifyContent: 'space-between' }}>
        {actions.map((item) => item)}
      </DialogActions>
    </Dialog>
  );
}

PopUp.defaultProps = {
  onClose: null,
  contentText: null,
  contentComponent: null,
};

PopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  contentText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  contentComponent: PropTypes.node,
  actions: PropTypes.node.isRequired,
};

export default PopUp;
