import { ButtonGroup } from '@mui/material';
import { useDateFilter } from 'context/DateFilterContext';
import PropTypes from 'prop-types';
import MDButton from 'shared/components/MDButton';

function ButtonGroupField({ form, options }) {
  const { setFieldValue, values } = form;
  const { type_date: type_dateV } = values;

  const { firstGraphDate } = useDateFilter();

  const handleButtonClick = (option) => {
    const today = new Date();
    let initialDate = firstGraphDate ?? '';
    let finalDate = today.toISOString().split('T')[0];

    if (option === 'week') {
      const lastWeekStart = new Date(today);
      lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
      initialDate = lastWeekStart.toISOString().split('T')[0];

      const lastWeekEnd = new Date(today);
      lastWeekEnd.setDate(today.getDate() - today.getDay() - 1);
      finalDate = lastWeekEnd.toISOString().split('T')[0];
    } else if (option === 'month') {
      const firstDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      initialDate = firstDayOfLastMonth.toISOString().split('T')[0];

      const lastDayOfLastMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      finalDate = lastDayOfLastMonth.toISOString().split('T')[0];
    } else if (option === 'all') {
      finalDate = today.toISOString().split('T')[0];
    }

    return { initialDate, finalDate };
  };

  return (
    <ButtonGroup className='btn-group-toggle' aria-label='Button group'>
      {options.map((option) => (
        <MDButton
          key={option.value}
          variant={type_dateV === option.value ? 'gradient' : 'outlined'}
          color={type_dateV === option.value ? 'primary' : 'secondary'}
          onClick={() => {
            const dates = handleButtonClick(option.value);
            setFieldValue('initial_date', dates.initialDate);
            setFieldValue('final_date', dates.finalDate);
            setFieldValue('type_date', option.value);
          }}
        >
          {option.label}
        </MDButton>
      ))}
    </ButtonGroup>
  );
}

ButtonGroupField.propTypes = {
  form: PropTypes.any.isRequired,
  options: PropTypes.any.isRequired,
};

export default ButtonGroupField;
