import { isAxiosError } from 'axios';
import { CRITERION_API, CRITERIONS_API } from './criterion';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';
import ReactDOMServer from 'react-dom/server';

const errorMsgHttp = (data, defaultMsg) => {
  if (typeof data === 'string') {
    return data;
    // switch (data) {
    //   case "name already in use":
    //     return "Nome ja está em uso.";

    //   case "Supervisor já tem 30 critérios":
    //     return "Supervisor já tem 30 critérios";

    //   default:
    //     return defaultMsg;
    // }
  } else {
    const element = (
      <div>
        {data.map((msg, i) => (
          <p key={i}>{msg}</p>
        ))}
      </div>
    );
    const jsxString = ReactDOMServer.renderToString(element);
    return jsxString;
  }
};

class CriterionService {
  create = async ({ name, role, status }) => {
    try {
      const response = await customAxios.post(CRITERION_API, {
        name,
        role,
        status,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível criar critério';

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 409:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${CRITERION_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível carregar critério');
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(`${CRITERIONS_API}${filter}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          default:
            throw new Error('Não foi possível listar usuários');
        }
      }

      throw new Error(error);
    }
  };

  edit = async (uuid, values) => {
    let name = values.name;
    let role = values.role;
    let status = values.status;

    const response = await customAxios.put(`${CRITERION_API}/${uuid}`, {
      name,
      role,
      status,
    });

    return response.data;
  };

  updateStatus = async ({ uuid, status }) => {
    try {
      const response = await customAxios.patch(`${CRITERION_API}/${uuid}`, {
        status,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = 'Não foi possível editar critério';
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          case 422:
            throw new Error(errorMsgHttp(error.response?.data, defaultMsg));

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${CRITERION_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;
        const defaultMsg = 'Não foi possível apagar critério';

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(error.response?.data);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };
}

export default new CriterionService();
