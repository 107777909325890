// @fullcalendar components
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export const calendarPlugins = [
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin,
];

const config = {
  plugins: calendarPlugins,
  locale: "pt-br",
  timeZone: "America/Sao_Paulo",
  headerToolbar: {
    left: "timeGridWeek dayGridMonth todayButton",
    center: "title",
    right: "previousButton nextButton",
    // right: "dayGridDay, dayGridMonth,timeGridWeek,timeGridDay,listWeek prev,next",
  },
  buttonText: {
    day: "DIA",
    month: "MÊS",
    week: "SEMANA",
    today: "Hoje",
    list: "LISTA",
  },
  // allDaySlot: false,
  allDayText: "Dia todo",
  slotDuration: "01:00:00",
  slotLabelInterval: { hours: 1 },
  slotLabelFormat: {
    hour: "2-digit",
    minute: "2-digit",
    omitZeroMinute: false,
    meridiem: "short",
  },
  showNonCurrentDates: false,
  fixedWeekCount: false,
  dayMaxEvents: 2,
  moreLinkContent: (args) => `+${args.num} eventos`,
  eventTimeFormat: {
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
    hour12: false,
  },
  views: {
    listAllYears: {
      type: "listYear",
      duration: { years: 999 },
    },
  },
  // firstDay: 1,
  // dayNames: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sabado"],
};

export default config;
