import { useTheme } from '@mui/material';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';

function GraphLegends({ label, onClick, color }) {
  const theme = useTheme();

  return (
    <MDBox
      display='flex'
      gap={1}
      alignItems='center'
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        padding: 1,
        '&:hover': {
          borderRadius: '5px',
          background: theme.palette['grey'][200],
        },
      }}
    >
      <MDBox
        sx={{
          borderRadius: '50%',
          height: '20px',
          width: '20px',
          backgroundColor: theme.palette[color].main,
        }}
      />
      <MDTypography variant='body2'>{label}</MDTypography>
    </MDBox>
  );
}

export default GraphLegends;
