import {
  Autocomplete,
  Box,
  Card,
  createFilterOptions,
  Grid,
} from '@mui/material';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import DashboardLayout from 'shared/examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'shared/examples/Navbars/DashboardNavbar';
import { useEffect, useState } from 'react';
import MDButton from 'shared/components/MDButton';
import { Form, Formik } from 'formik';
import FormField from 'shared/components/form_field';
import initialValues from '../schemas/initial_values';
import validations from '../schemas/validations';
import {
  setValue,
  renderInput,
  handleValue,
} from 'shared/components/autocomplete/autocompleteConfigs';
import {
  rolesOptions,
  translateRoleNumberToString,
  translateStatus,
  statusOptions,
} from 'utils/essentialData';
import toastError from 'shared/components/snackbar/error/toastError';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import { useNavigate, useParams } from 'react-router-dom';
import PopUpDelete from 'shared/components/pop_up/popUpDelete';
import CriterionService from 'shared/services/criterion/criterion.service';

function CriterionEdit() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const filterOptionsRole = createFilterOptions({
    stringify: translateRoleNumberToString,
  });
  const filterOptionsStatus = createFilterOptions({
    stringify: translateStatus,
  });
  const handleSubmit = async (values, actions) => {
    try {
      await CriterionService.edit(uuid, values);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        'Critério editado com sucesso',
        '',
        true,
        '/criterios',
        'Redirecionar para critérios'
      );
    } catch (e) {
      toastError(e.message);
    }
  };

  const loadCriterion = async (page = 1, limit = 5) => {
    const res = await CriterionService.read(`${uuid}`);

    try {
      setInitialValuesFromApi({
        name: res.name ?? '',
        role: res.role ?? '',
        status: res.status ?? '',
      });
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleDelete = () => setOpenDeletePopup(true);
  const handleCancelDelete = () => setOpenDeletePopup(false);
  const handleApplyDelete = async () => {
    try {
      await CriterionService.delete(uuid);

      toastSuccess('Critério da avaliação apagado com sucesso');
      navigate('/criterios');
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenDeletePopup(false);
    }
  };

  useEffect(() => {
    loadCriterion();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValuesFromApi ?? initialValues}
        validationSchema={validations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const { name: nameV, role: roleV, status: statusV } = values;
          return (
            <Form id='user-create-form' autoComplete='off'>
              <Card id='basic-info' sx={{ overflow: 'visible', p: 3 }}>
                <MDBox pb={3}>
                  <MDTypography variant='h5'>
                    Editar Critério da Avaliação
                  </MDTypography>
                </MDBox>

                <MDBox>
                  <FormField
                    name='name'
                    label='Critério de avaliação *'
                    type='text'
                    value={nameV}
                    error={errors.name && touched.name}
                    success={nameV.length > 0 && !errors.name}
                    multiline
                    rows={3}
                  />
                </MDBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      disabled
                      options={rolesOptions ?? []}
                      getOptionLabel={translateRoleNumberToString}
                      value={handleValue(roleV)}
                      onChange={(e, value) => {
                        setValue(setFieldValue, 'role', value);
                        setValue(setFieldValue, 'supervisor_uuids', []);
                      }}
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          {translateRoleNumberToString(option)}
                        </Box>
                      )}
                      filterOptions={filterOptionsRole}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'role',
                          'Perfil *',
                          handleBlur
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={statusOptions ?? []}
                      getOptionLabel={translateStatus}
                      value={handleValue(statusV)}
                      onChange={(e, value) => {
                        setFieldValue('status', value);
                      }}
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          {translateStatus(option)}
                        </Box>
                      )}
                      filterOptions={filterOptionsStatus}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'status',
                          'Status *',
                          handleBlur
                        )
                      }
                    />
                  </Grid>
                </Grid>

                <MDBox
                  display='flex'
                  alignItems='space-between'
                  gap={3}
                  mt={2}
                  flexDirection={{
                    xs: 'column',
                    sm: 'row',
                  }}
                >
                  <MDButton
                    color='error'
                    type='button'
                    size='large'
                    onClick={handleDelete}
                    variant='outlined'
                  >
                    excluir
                  </MDButton>

                  <MDBox
                    display='flex'
                    width={{
                      xs: '100%',
                      sm: '24rem',
                    }}
                    ml='auto'
                    gap={3}
                    flexDirection={{
                      xs: 'column',
                      sm: 'row',
                    }}
                  >
                    <MDButton
                      color='secondary'
                      type='button'
                      size='large'
                      onClick={() => navigate('/criterios')}
                      fullWidth
                      variant='outlined'
                    >
                      cancelar
                    </MDButton>
                    <MDButton
                      disabled={isSubmitting}
                      color='primary'
                      type='submit'
                      size='large'
                      fullWidth
                    >
                      SALVAR
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>

      <PopUpDelete
        open={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title='Exclusão de critério'
        contentText='Tem certeza que deseja excluir este critério?'
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelDelete}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton color='primary' onClick={handleApplyDelete} key='confirm'>
            confirmar exclusão
          </MDButton>,
        ]}
      />
    </DashboardLayout>
  );
}

export default CriterionEdit;
