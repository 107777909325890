/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';

// Material Dashboard 2 PRO React components
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import MDButton from 'shared/components/MDButton';

// Authentication layout components
import BasicLayout from 'layouts/authentication/components/BasicLayout';

// Images
import { useAuthContext } from 'context/AuthContext';
import base64 from 'base-64';
import logo from '../../../assets/images/logo-clinica-espasso.png';

//Icons
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Snackbar from 'shared/components/snackbar';
import { Form, Formik } from 'formik';
import initialValues from './schemas/initial_values';
import validations from './schemas/validations';
import FormField from 'shared/components/form_field';
import { userLogged } from 'utils/essentialData';
import FormFieldRadio from 'shared/components/form_field/form_field_radio';
import { maskCPF } from 'utils/masks';
import toastError from 'shared/components/snackbar/error/toastError';

const radioOptionsType = [
  {
    value: 'email',
    label: 'E-mail',
  },
  {
    value: 'cpf',
    label: 'CPF',
  },
];

function Login() {
  const navigate = useNavigate();
  const { handleLogin, handleKeepLogged, keeplogged, token } = useAuthContext();

  const toPage = () => {
    if (userLogged().role === 99) {
      navigate('/dashboard');
    } else {
      navigate('/calendar');
    }
  };

  const handleSubmit = async (values, actions) => {
    const { type, document, password } = values;

    try {
      // const encoded = base64
      //   .encode(`${document}:${password}`)
      //   .toString("base64");

      await handleLogin(values, `?type=${type}`);

      actions.setTouched({});
      actions.setSubmitting(false);

      toPage();
    } catch (e) {
      toastError(e.message);
    }
  };

  useEffect(() => {
    if (keeplogged && token) toPage();
  }, [keeplogged]);

  return (
    <BasicLayout>
      <MDBox
        p={2}
        mb={5}
        textAlign='center'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <img alt='logo' src={logo} width='100%' />
      </MDBox>
      <Card>
        <MDBox
          variant='gradient'
          borderRadius='lg'
          bgColor='primary'
          coloredShadow='info'
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign='center'
        >
          <MDTypography variant='h4' fontWeight='medium' color='white'>
            Login
          </MDTypography>
        </MDBox>
        <MDBox pt={2} px={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={validations[0]}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              const {
                type: typeV,
                document: documentV,
                password: passwordV,
              } = values;

              return (
                <Form id='login-form' autoComplete='off'>
                  <FormFieldRadio
                    name='type'
                    value={typeV}
                    options={radioOptionsType}
                  />

                  <FormField
                    fieldVariant='outlined'
                    name='document'
                    label={typeV === 'email' ? 'E-mail' : 'CPF *'}
                    type='text'
                    value={documentV}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (typeV === 'email') {
                        setFieldValue('document', value);
                      } else setFieldValue('document', maskCPF(value));
                    }}
                    error={errors.document && touched.document}
                    success={documentV.length > 0 && !errors.document}
                  />

                  <FormField
                    fieldVariant='outlined'
                    name='password'
                    label='Senha *'
                    type='password'
                    value={passwordV}
                    error={errors.password && touched.password}
                    success={passwordV.length > 0 && !errors.password}
                  />

                  <MDBox
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <MDTypography
                      variant='button'
                      fontWeight='regular'
                      color='primary'
                      sx={{
                        cursor: 'pointer',
                      }}
                      onClick={() => navigate('/validate')}
                    >
                      Primeiro acesso | Recuperar sua senha
                    </MDTypography>
                  </MDBox>

                  <MDBox mt={4}>
                    <MDButton
                      disabled={isSubmitting}
                      color='primary'
                      type='submit'
                      size='large'
                      fullWidth
                    >
                      AUTENTICAR
                    </MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
        <MDBox
          px={3}
          py={1}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Switch
            checked={keeplogged}
            onChange={(_, v) => handleKeepLogged(v)}
          />
          <MDTypography variant='button' fontWeight='regular'>
            Salvar meus dados
          </MDTypography>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
