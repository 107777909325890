//region DIVISIONS
export function createAnnotations(
  divisionAPosition,
  divisionBPosition,
  divisionCPosition
) {
  const annotations = {};

  if (divisionAPosition !== 0) {
    annotations.a = {
      type: 'line',
      scaleID: 'x',
      value: divisionAPosition - 0.5,
      borderColor: 'rgba(0, 0, 0, 1)',
      borderWidth: 2,
    };
  }

  if (divisionBPosition !== 0) {
    annotations.b = {
      type: 'line',
      scaleID: 'x',
      value: divisionBPosition - 0.5,
      borderColor: 'rgba(0, 0, 0, 1)',
      borderWidth: 2,
    };
  }

  if (divisionCPosition !== 0) {
    annotations.c = {
      type: 'line',
      scaleID: 'x',
      value: divisionCPosition - 0.5,
      borderColor: 'rgba(0, 0, 0, 1)',
      borderWidth: 2,
    };
  }

  return annotations;
}

//region DATASETS
const generateDatasets = (data, palette) => {
  const datasets = [];

  // Baseline - apenas adicionar se houver dados
  if (
    data.baseline.corrects.length > 0 ||
    data.baseline.incorrects.length > 0
  ) {
    if (data.baseline.incorrects.length > 0) {
      datasets.push({
        data: data.baseline.incorrects,
        label: 'erros baseline',
        borderColor: palette['error'].main,
        borderWidth: 2,
        fill: false,
        yAxisID: 'y1',
      });
    }

    if (data.baseline.corrects.length > 0) {
      datasets.push({
        data: data.baseline.corrects,
        label: 'acertos baseline',
        borderColor: palette['success'].main,
        borderWidth: 2,
        fill: false,
        yAxisID: 'y2',
      });
    }
  }

  // Intervention With Help
  if (
    data.interventionWithHelp.corrects.length > 0 ||
    data.interventionWithHelp.incorrects.length > 0
  ) {
    if (data.interventionWithHelp.incorrects.length > 0) {
      datasets.push({
        data: data.interventionWithHelp.incorrects,
        label: 'erros intervenção com ajuda',
        borderColor: palette['error'].main,
        borderWidth: 2,
        fill: false,
        yAxisID: 'y1',
      });
    }

    if (data.interventionWithHelp.corrects.length > 0) {
      datasets.push({
        data: data.interventionWithHelp.corrects,
        label: 'acertos intervenção com ajuda',
        borderColor: palette['success'].main,
        borderWidth: 2,
        fill: false,
        yAxisID: 'y2',
      });
    }

    if (data.interventionWithHelp.correctsWHelp.length > 0) {
      datasets.push({
        data: data.interventionWithHelp.correctsWHelp,
        label: 'acertos com ajuda intervenção com ajuda',
        borderColor: palette['ternary'].main,
        borderWidth: 2,
        fill: false,
        yAxisID: 'y3',
      });
    }
  }

  // Intervention Independent
  if (
    data.interventionIndependent.corrects.length > 0 ||
    data.interventionIndependent.incorrects.length > 0
  ) {
    if (data.interventionIndependent.incorrects.length > 0) {
      datasets.push({
        data: data.interventionIndependent.incorrects,
        label: 'erros intervenção independente',
        borderColor: palette['error'].main,
        borderWidth: 2,
        fill: false,
        yAxisID: 'y1',
      });
    }

    if (data.interventionIndependent.corrects.length > 0) {
      datasets.push({
        data: data.interventionIndependent.corrects,
        label: 'acertos intervenção independente',
        borderColor: palette['success'].main,
        borderWidth: 2,
        fill: false,
        yAxisID: 'y2',
      });
    }
  }

  // Maintenance
  if (
    data.maintenance.corrects.length > 0 ||
    data.maintenance.incorrects.length > 0
  ) {
    if (data.maintenance.incorrects.length > 0) {
      datasets.push({
        data: data.maintenance.incorrects,
        label: 'erros manutenção',
        borderColor: palette['error'].main,
        borderWidth: 2,
        fill: false,
        yAxisID: 'y1',
      });
    }

    if (data.maintenance.corrects.length > 0) {
      datasets.push({
        data: data.maintenance.corrects,
        label: 'acertos manutenção',
        borderColor: palette['success'].main,
        borderWidth: 2,
        fill: false,
        yAxisID: 'y2',
      });
    }
  }

  return datasets;
};
