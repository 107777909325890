import {
  Autocomplete,
  Box,
  Card,
  createFilterOptions,
  Grid,
} from '@mui/material';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import DashboardLayout from 'shared/examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'shared/examples/Navbars/DashboardNavbar';
import MDButton from 'shared/components/MDButton';
import { Form, Formik } from 'formik';
import FormField from 'shared/components/form_field';
import initialValues from '../schemas/initial_values';
import validations from '../schemas/validations';
import {
  setValue,
  renderInput,
  handleValue,
} from 'shared/components/autocomplete/autocompleteConfigs';
import CriterionService from 'shared/services/criterion/criterion.service';
import {
  rolesOptions,
  translateRoleNumberToString,
  translateStatus,
  statusOptions,
} from 'utils/essentialData';
import toastError from 'shared/components/snackbar/error/toastError';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';

function CriterionCreate() {
  const filterOptionsRole = createFilterOptions({
    stringify: translateRoleNumberToString,
  });

  const filterOptionsStatus = createFilterOptions({
    stringify: translateStatus,
  });

  const handleSubmit = async (values, actions) => {
    try {
      await CriterionService.create(values);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess(
        'Critério criado com sucesso',
        '',
        true,
        '/criterios',
        'Redirecionar para critérios'
      );
    } catch (e) {
      toastError(e.message);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        validationSchema={validations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const { name: nameV, role: roleV, status: statusV } = values;
          return (
            <Form id='user-create-form' autoComplete='off'>
              <Card id='basic-info' sx={{ overflow: 'visible', p: 3 }}>
                <MDBox pb={3}>
                  <MDTypography variant='h5'>
                    Novo Critério da Avaliação
                  </MDTypography>
                </MDBox>

                <MDBox>
                  <FormField
                    name='name'
                    label='Critério de avaliação *'
                    type='text'
                    value={nameV}
                    error={errors.name && touched.name}
                    success={nameV.length > 0 && !errors.name}
                    multiline
                    rows={3}
                  />
                </MDBox>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      disabled
                      options={rolesOptions ?? []}
                      getOptionLabel={translateRoleNumberToString}
                      value={handleValue(roleV)}
                      onChange={(e, value) => {
                        setValue(setFieldValue, 'role', value);
                        setValue(setFieldValue, 'supervisor_uuids', []);
                      }}
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          {translateRoleNumberToString(option)}
                        </Box>
                      )}
                      filterOptions={filterOptionsRole}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'role',
                          'Perfil *',
                          handleBlur
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      options={statusOptions ?? []}
                      getOptionLabel={translateStatus}
                      value={handleValue(statusV)}
                      onChange={(e, value) => {
                        setFieldValue('status', value);
                      }}
                      renderOption={(props, option) => (
                        <Box component='li' {...props}>
                          {translateStatus(option)}
                        </Box>
                      )}
                      filterOptions={filterOptionsStatus}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'status',
                          'Status *',
                          handleBlur
                        )
                      }
                    />
                  </Grid>
                </Grid>

                <MDBox display='flex' gap='5px' pt={3} ml='auto'>
                  <MDButton
                    disabled={isSubmitting}
                    color='primary'
                    type='submit'
                    size='large'
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default CriterionCreate;
