/* eslint-disable no-console */
import { isAxiosError } from "axios";
import { CEP_API } from "./api_utils";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";

export async function getCep(cep) {
  try {
    const response = await customAxios.get(`${CEP_API}?cep=${cep}`);
    if (!response) new Error("Não foi possível buscar informações do CEP");
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      const status = error.response?.status;

      switch (status) {
        case 401:
          throw new Error(errorStatusCode(401));

        default:
          throw new Error("Não foi possível buscar informações do CEP");
      }
    }

    throw new Error(error);
  }
}

// export async function getFile(key) {
//   try {
//     const ROUTE = `upload?key=${key}`;
//     const URL = URL_API + ROUTE;
//     const response = await axios.get(URL).then((res) => {
//       const url = formatFileBase64(res.data?.type, res.data?.fileToBase64);
//       const link = document.createElement("a");
//       link.href = url;

//       const nameFormat = res.data?.url?.split(".com/")[1];
//       const [result] = nameFormat.split("?AWS");

//       link.setAttribute("download", result.slice(33));
//       document.body.appendChild(link);
//       link.click();
//       return res.data;
//     });
//     return response.data;
//   } catch (e) {
//     throw Error(errorMsgHttp(e.response?.data));
//   }
// }
