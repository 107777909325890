import { isAxiosError } from 'axios';
import { APPLICATION_PHASE_API } from './applicationPhases';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';

class ApplicationPhasesService {
  read = async (uuid) => {
    try {
      const response = await customAxios.get(
        `${APPLICATION_PHASE_API}/${uuid}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível carregar avaliação');
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(
        `${APPLICATION_PHASE_API}${filter}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível listar avaliaçõess');
        }
      }

      throw new Error(error);
    }
  };
}

export default new ApplicationPhasesService();
