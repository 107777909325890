const customLabelsPlugin = () => {
  return {
    id: 'customLabelsPlugin',
    beforeDraw: (chart) => {
      const { ctx, chartArea, data } = chart;
      const { datasets } = data;
      const width = chartArea.right - chartArea.left;

      ctx.save();
      ctx.font = 'bold 12px Arial';
      ctx.fillStyle = '#000';
      ctx.textAlign = 'center';

      const offset = -10;

      const errorDatasets = datasets.filter((dataset) =>
        dataset.label.toLowerCase().includes('erros')
      );

      const totalErrorDataCount = errorDatasets.reduce(
        (sum, dataset) =>
          sum + dataset.data.filter((val) => val !== null).length,
        0
      );

      const positions = errorDatasets
        .map((dataset, index) => {
          const dataCount = dataset.data.filter((val) => val !== null).length;
          if (dataCount === 0) {
            return null;
          }
          const sectionWidth = (dataCount / totalErrorDataCount) * width;
          const start =
            chartArea.left +
            (index === 0
              ? 0
              : errorDatasets.slice(0, index).reduce((sum, prevDataset) => {
                  const prevDataCount = prevDataset.data.filter(
                    (val) => val !== null
                  ).length;
                  return sum + (prevDataCount / totalErrorDataCount) * width;
                }, 0));
          const end = start + sectionWidth;
          return { label: dataset.label, start, end, width: sectionWidth };
        })
        .filter((position) => position !== null);

      const labels = {
        'erros baseline': { text: 'Linha de Base', letter: 'A' },
        'erros intervenção com ajuda': {
          text: 'Intervenção com Ajuda',
          letter: 'B',
        },
        'erros intervenção independente': {
          text: 'Intervenção Independente',
          letter: 'B',
        },
        'erros manutenção': { text: 'Manutenção', letter: 'C' },
      };

      let interventionStart = null;
      let interventionEnd = null;

      positions.forEach(({ label, start, end }) => {
        const { text, letter } = labels[label] || { text: label, letter: '' };
        const midPosition = (start + end) / 2 + offset;
        if (letter === 'B') {
          interventionStart =
            interventionStart === null
              ? start
              : Math.min(interventionStart, start);
          interventionEnd =
            interventionEnd === null ? end : Math.max(interventionEnd, end);
          ctx.fillText(text, midPosition, chartArea.top - 5);
        } else {
          ctx.fillText(letter, midPosition, chartArea.top - 20);
          ctx.fillText(text, midPosition, chartArea.top - 5);
        }
      });

      // Desenha a letra "B" apenas uma vez, centralizada entre as intervenções
      if (interventionStart !== null && interventionEnd !== null) {
        const midPosition = (interventionStart + interventionEnd) / 2 + offset;
        ctx.fillText('B', midPosition, chartArea.top - 20);
      }

      ctx.restore();
    },
  };
};

export default customLabelsPlugin;
