/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  Chip,
  Grid,
  Icon,
  IconButton,
  useTheme,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "shared/components/MDBox";
import MDInput from "shared/components/MDInput";
import MDTypography from "shared/components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "shared/examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "shared/examples/Navbars/DashboardNavbar";

import { useNavigate, useParams } from "react-router-dom";

import calendarService from "shared/services/calendar/calendar.service";
import usersService from "shared/services/users/users.service";
import patientsService from "shared/services/patients/patients.service";

import EventCalendar from "shared/components/eventCalendar";
import calendarConfig from "shared/components/eventCalendar/calendarConfig";
import dataMock from "./dataMock";
import MDButton from "shared/components/MDButton";

import CreateSchedule, { dialogButton } from "./create_schedule";
import CreateAppointment from "./create_appointment";
import { validationsCalendarSearch } from "./schemas/validations";
import { initialValuesCalendarSearch } from "./schemas/initial_values";
import { Form, Formik } from "formik";
import {
  handleValue,
  setValue,
  renderInput,
  compareOptionToValue,
} from "shared/components/autocomplete/autocompleteConfigs";
import FormFieldAutocomplete from "shared/components/form_field/form_field_autocomplete";
import PopUp from "shared/components/pop_up";
import { userLogged } from "utils/essentialData";
import DeleteSchedule from "./delete_schedule";
import toastError from "./../../components/snackbar/error/toastError";

const openPopUpDefault = { open: false, data: {} };

const allFilter = { uuid: "1", name: "Todos" };

function CalendarTherapists() {
  const theme = useTheme();
  const multipleAutoChipsStyles = { backgroundColor: theme.palette.info.main };
  const { uid } = useParams();
  const navigate = useNavigate();
  const currentMonthly = new Date();
  const userRole = userLogged();

  const [openSchedule, setOpenSchedule] = useState(openPopUpDefault);
  const [openAppointment, setOpenAppointment] = useState(openPopUpDefault);
  const [openDelAppointment, setOpenDelAppointment] =
    useState(openPopUpDefault);

  const [calendar, setCalendar] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterSearch, setFilterSearch] = useState("");

  const [therapistData, setTherapistData] = useState();
  const [patientData, setPatientData] = useState();

  const loadTherapists = async () => {
    try {
      const filter = "";
      const filterValue = filter !== "" ? `&filter=${filter.trim()}` : "";
      const res = await usersService.list(
        `?page=1&limit=200&role=2${filterValue}`
      );
      if (res) setTherapistData(res);
    } catch (error) {
      throw Error(toastError(error.message));
    }
  };

  const loadPatients = async () => {
    try {
      const filter = "";
      const filterValue = filter !== "" ? `&filter=${filter.trim()}` : "";
      const res = await patientsService.list(
        `?page=1&limit=200${filterValue}`
      );
      if (res) setPatientData(res);
    } catch (error) {
      throw Error(toastError(error.message));
    }
  };

  // calendar
  const handleDateClick = (value) => {
    if (userRole?.role !== 2) setOpenSchedule({ open: true, data: value });
  };

  const prevMonth = (valueRef) => {
    const calendarApi = valueRef.current.getApi();
    calendarApi.prev();
    setStartDate(calendarApi.view.activeStart.toISOString().split("T")[0]);
    setEndDate(calendarApi.view.activeEnd.toISOString().split("T")[0]);
  };

  const nextMonth = (valueRef) => {
    const calendarApi = valueRef.current.getApi();
    calendarApi.next();
    setStartDate(calendarApi.view.activeStart.toISOString().split("T")[0]);
    setEndDate(calendarApi.view.activeEnd.toISOString().split("T")[0]);
  };

  const goToday = (valueRef) => {
    const calendarApi = valueRef.current.getApi();
    calendarApi.today();
    setStartDate(calendarApi.view.activeStart.toISOString().split("T")[0]);
    setEndDate(calendarApi.view.activeEnd.toISOString().split("T")[0]);
  };

  const loadCalendar = async (startDate, endDate, filter = "") => {
    try {
      const res = await calendarService.list(
        `?start_date=${startDate}&end_date=${endDate}${filter}`
      );
      if (res) setCalendar(res);
    } catch (error) {
      toastError(error.message);
    }
  };

  const initialCalendar = () => {
    let monthStart,
      monthEnd,
      filterString = "";
    const date = new Date();
    monthStart = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
    monthEnd = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      1
    ).toISOString();

    if (userRole?.role === 2) {
      filterString = `&owner[]=${userRole?.uuid}`;
      setFilterSearch(filterString);
    }

    setStartDate(monthStart.split("T")[0]);
    setEndDate(monthEnd.split("T")[0]);
    loadCalendar(
      monthStart.split("T")[0],
      monthEnd.split("T")[0],
      filterString
    );
  };

  const handleSearch = (values, actions) => {
    const { owner, patient_uuid } = values;
    let filterString = "";
    if (owner !== "" && owner[0]?.uuid !== "1")
      owner.forEach((v) => (filterString += `&owner[]=${v?.uuid}`));
    if (patient_uuid !== "" && patient_uuid[0]?.uuid !== "1")
      patient_uuid.forEach((v) => (filterString += `&patient[]=${v?.uuid}`));

    setFilterSearch(filterString);
    loadCalendar(startDate, endDate, filterString);
    actions.setSubmitting(false);
  };

  const handleConfirmPopups = () => {
    setOpenSchedule(openPopUpDefault);
    setOpenAppointment(openPopUpDefault);
    setOpenDelAppointment(openPopUpDefault);

    loadCalendar(startDate, endDate, filterSearch);
  };

  useEffect(() => {
    loadTherapists();
    loadPatients();
    initialCalendar();
    return () => {
      setCalendar([]);
    };
  }, []);

  useEffect(() => {
    if (calendar) loadCalendar(startDate, endDate, filterSearch);
  }, [endDate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ padding: theme.spacing(2) }}>
        <MDBox display="flex" justifyContent="center">
          <MDTypography
            component="div"
            variant="h4"
            color="info"
            fontWeight="regular"
          >
            Calendário
          </MDTypography>
        </MDBox>

        <Grid container direction="row" gap={0}>
          <Grid item xs={12} sm={2.5} pr={1} py={2}>
            <Formik
              initialValues={initialValuesCalendarSearch}
              validationSchema={validationsCalendarSearch[0]}
              onSubmit={handleSearch}
              enableReinitialize
            >
              {({ values, isSubmitting, setFieldValue, handleBlur }) => {
                const { owner: ownerV, patient_uuid: patient_uuidV } = values;
                return (
                  <Form id="user-create-form" autoComplete="off">
                    <Grid container spacing={1}>
                      {userRole?.role !== 2 && (
                        <Grid item xs={12} sm={12}>
                          <Autocomplete
                            multiple
                            options={
                              therapistData?.data
                                ? [...therapistData?.data, allFilter]
                                : []
                            }
                            getOptionLabel={(opt) => opt.name ?? ""}
                            value={handleValue(ownerV)}
                            isOptionEqualToValue={compareOptionToValue}
                            onChange={(e, value) => {
                              const filterAll = value.filter(
                                (e) => e.uuid === "1"
                              );
                              if (filterAll.length > 0) {
                                setValue(setFieldValue, "owner", filterAll);
                              } else {
                                setValue(setFieldValue, "owner", value);
                              }
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  style={multipleAutoChipsStyles}
                                  label={option.name}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <FormFieldAutocomplete
                                fieldVariant="standard"
                                {...params}
                                fieldShrink
                                type="text"
                                label="Selecione um terapeuta"
                                placeholder="Pesquisar"
                                name="owner"
                                onBlur={handleBlur}
                              />
                            )}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12}>
                        <Autocomplete
                          multiple
                          options={
                            patientData?.data
                              ? [...patientData?.data, allFilter]
                              : []
                          }
                          getOptionLabel={(opt) => opt.name ?? ""}
                          value={handleValue(patient_uuidV)}
                          isOptionEqualToValue={compareOptionToValue}
                          onChange={(e, value) => {
                            const filterAll = value.filter(
                              (e) => e.uuid === "1"
                            );
                            if (filterAll.length > 0) {
                              setValue(
                                setFieldValue,
                                "patient_uuid",
                                filterAll
                              );
                            } else {
                              setValue(setFieldValue, "patient_uuid", value);
                            }
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                style={multipleAutoChipsStyles}
                                label={option.name}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <FormFieldAutocomplete
                              fieldVariant="standard"
                              {...params}
                              fieldShrink
                              type="text"
                              label="Selecione um paciente"
                              placeholder="Pesquisar"
                              name="patient_uuid"
                              onBlur={handleBlur}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <MDButton
                      disabled={isSubmitting}
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      BUSCAR
                    </MDButton>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
          <Grid item xs={12} sm={9.5}>
            {useMemo(
              () => (
                <EventCalendar
                  {...calendarConfig}
                  initialView="dayGridMonth"
                  initialDate={currentMonthly}
                  events={
                    calendar
                    // dataMock
                  }
                  // selectable="false"
                  dateClick={handleDateClick}
                  eventClick={(info) => {
                    setOpenAppointment({
                      open: true,
                      data: info.event,
                    });
                  }}
                  prevMonth={prevMonth}
                  nextMonth={nextMonth}
                  goToday={goToday}
                />
              ),
              [calendar]
            )}
          </Grid>
        </Grid>
      </Card>

      <CreateSchedule
        open={openSchedule}
        setOpen={setOpenSchedule}
        onClose={handleConfirmPopups}
        therapistsOptions={therapistData}
      />

      <CreateAppointment
        open={openAppointment}
        setOpen={setOpenAppointment}
        onClose={handleConfirmPopups}
        patientOptions={patientData}
        therapistOptions={therapistData}
        setOpenDel={setOpenDelAppointment}
      />

      <DeleteSchedule
        open={openDelAppointment}
        setOpen={setOpenDelAppointment}
        onClose={handleConfirmPopups}
      />
    </DashboardLayout>
  );
}

export default CalendarTherapists;
