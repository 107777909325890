import { isAxiosError } from 'axios';
import {
  SESSION_BEHAVIOR_API,
  SESSION_BEHAVIORS_API,
} from './session_behaviors';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';

class SessionBehaviorsService {
  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${SESSION_BEHAVIOR_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error(
              'Não foi possível carregar comportamentos da sessão'
            );
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(
        `${SESSION_BEHAVIORS_API}${filter}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível listar sessões');
        }
      }

      throw new Error(error);
    }
  };

  create = async ({
    application_phase,
    value,
    session_uuid,
    behavior_uuid,
  }) => {
    try {
      const response = await customAxios.post(`${SESSION_BEHAVIOR_API}`, {
        application_phase,
        value,
        session_uuid,
        behavior_uuid,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível criar comportamento da sessão');
        }
      }

      throw new Error(error);
    }
  };

  edit = async (
    uuid,
    { application_phase, value, session_uuid, behavior_uuid }
  ) => {
    try {
      const response = await customAxios.put(
        `${SESSION_BEHAVIOR_API}/${uuid}`,
        {
          application_phase,
          value,
          session_uuid,
          behavior_uuid,
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível editar comportamento da sessão');
        }
      }

      throw new Error(error);
    }
  };

  updateStatus = async ({ uuid, status }) => {
    try {
      const response = await customAxios.patch(
        `${SESSION_BEHAVIOR_API}/${uuid}`,
        {
          status,
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = 'Não foi possível editar comportamento da sessão';
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(error.response?.data);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(
        `${SESSION_BEHAVIOR_API}/${uuid}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível apagar comportamento da sessão');
        }
      }

      throw new Error(error);
    }
  };
}

export default new SessionBehaviorsService();
