// dev
// const base_url = 'https://api.dev.clinicaespasso.facedigital.com.br';

// homol
//const base_url = "https://api.hmlclinicaespasso.facedigital.com.br";

// local
// const base_url = 'http://127.0.0.1:3333';

// prod
const base_url = 'https://api.clinicaespasso.programaespasso.com.br';

export default base_url;
