import { isAxiosError } from "axios";
import { MEETING_API, MEETINGS_API } from "./meetings";
import customAxios from "../axiosCustom";
import errorStatusCode from "utils/errorStatusCode";

class MeetingsService {
  create = async (
    participants,
    description,
    school,
    patient_uuid,
    type,
    date,
    current_therapist
  ) => {
    try {
      const response = await customAxios.post(
        `${MEETING_API}/patient/${patient_uuid}`,
        {
          participants,
          description,
          school,
          patient_uuid,
          type,
          date,
          current_therapist,
        }
      );

      if (!response) new Error("Não foi possível criar orientação");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível criar orientação");
        }
      }

      throw new Error(error);
    }
  };

  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${MEETING_API}/${uuid}`);

      if (!response) new Error("Não foi possível carregar orientação");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível carregar orientação");
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = "") => {
    try {
      const response = await customAxios.get(`${MEETINGS_API}${filter}`);

      if (!response) new Error("Não foi possível listar orientações");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível listar orientações");
        }
      }

      throw new Error(error);
    }
  };

  edit = async (
    uuid,
    participants,
    description,
    school,
    patient_uuid,
    type,
    date,
    current_therapist
  ) => {
    try {
      const response = await customAxios.put(`${MEETING_API}/${uuid}`, {
        participants,
        description,
        school,
        patient_uuid,
        type,
        date,
        current_therapist,
      });

      if (!response) new Error("Não foi possível editar orientação");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível editar orientação");
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(`${MEETING_API}/${uuid}`);

      if (!response) new Error("Não foi possível apagar orientação");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error("Não foi possível apagar orientação");
        }
      }

      throw new Error(error);
    }
  };
}

export default new MeetingsService();
