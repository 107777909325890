import { Card, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

import Styles from './style.js';

import MDBox from 'shared/components/MDBox';
import Programs from './programs';
import Behaviors from './behaviors';
import BehaviorsABCList from './behaviors_abc/behaviors_abc_list.js';

function getTabs() {
  return ['Programas', 'Comportamentos monitorados', 'Comportamentos ABC'];
}

function getStepContent(stepIndex, setStep) {
  switch (stepIndex) {
    case 0:
      return <Programs />;

    case 1:
      return <Behaviors />;

    case 2:
      return <BehaviorsABCList />;

    default:
      return null;
  }
}

function ServiceFolder() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [serviceFolderStep, setServiceFolderStep] = useState(0);
  const tabs = getTabs();

  const handleSetTabValue = (event, newValue) => setServiceFolderStep(newValue);

  return (
    <Card id='basic-info' sx={{ overflow: 'visible', p: 3 }}>
      <MDBox>
        <Tabs
          orientation={smallScreen ? 'vertical' : 'horizontal'}
          value={serviceFolderStep}
          onChange={handleSetTabValue}
          sx={Styles.tabsInline}
        >
          {tabs.map((label) => (
            <Tab key={label} label={label} sx={Styles.tabInline} />
          ))}
        </Tabs>
      </MDBox>

      <MDBox>{getStepContent(serviceFolderStep, setServiceFolderStep)}</MDBox>
    </Card>
  );
}

export default ServiceFolder;
