import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTheme } from '@mui/material';

// Register Chart.js modules
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarGraph = ({ graphData }) => {
  const theme = useTheme();
  const { palette } = theme;

  const data = {
    labels: graphData?.labels || [],
    datasets: [
      {
        label: 'Acerto de avaliações',
        data: graphData?.notes || [],
        backgroundColor: palette.dark.main,
        borderColor: palette.dark.main,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 25,
        },
        title: {
          display: true,
          text: '% de acertos',
        },
      },
      x: {
        title: {
          display: true,
          text: 'datas de avaliações',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default React.memo(BarGraph);
