import { isAxiosError } from 'axios';
import {
  SESSION_BEHAVIOR_OBSERVATION_API,
  SESSION_BEHAVIOR__OBSERVATIONS_API,
} from './session_behavior_observations';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';

class SessionsBehaviorObservationsService {
  read = async (uuid) => {
    try {
      const response = await customAxios.get(
        `${SESSION_BEHAVIOR_OBSERVATION_API}/${uuid}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error(
              'Não foi possível carregar comportamentos da sessão'
            );
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(
        `${SESSION_BEHAVIOR__OBSERVATIONS_API}${filter}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível listar sessões');
        }
      }

      throw new Error(error);
    }
  };

  create = async ({ behavior_session_uuid, observation }) => {
    try {
      const response = await customAxios.post(
        `${SESSION_BEHAVIOR_OBSERVATION_API}`,
        {
          behavior_session_uuid,
          observation,
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível criar comportamento da sessão');
        }
      }

      throw new Error(error);
    }
  };

  edit = async (uuid, { behavior_session_uuid, observation }) => {
    try {
      const response = await customAxios.put(
        `${SESSION_BEHAVIOR_OBSERVATION_API}/${uuid}`,
        {
          behavior_session_uuid,
          observation,
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível editar comportamento da sessão');
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(
        `${SESSION_BEHAVIOR_OBSERVATION_API}/${uuid}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível apagar comportamento da sessão');
        }
      }

      throw new Error(error);
    }
  };
}

export default new SessionsBehaviorObservationsService();
