/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo, useEffect, useState } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from 'react-table';

// @mui material components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Icon from '@mui/material/Icon';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Autocomplete from '@mui/material/Autocomplete';
import { IconButton, Menu } from '@mui/material';

// Material Dashboard 2 PRO React components
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import MDInput from 'shared/components/MDInput';
import MDPagination from 'shared/components/MDPagination';

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from 'shared/components/tables/DataTableHeadCell';
import DataTableBodyCell from 'shared/components/tables/DataTableBodyCell';
import { navbarIconButton } from 'shared/examples/Navbars/DashboardNavbar/styles';

function DatatableApi({
  entriesPerPage,
  showEntriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  onRowSelected,
  pagination,
  isSorted,
  noEndBorder,
  setTableLimit,
  tableLimit,
  setTablePage,
  tablePage,
  tableTotal,
  children,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ['5', '10', '15', '20', '25', '50'];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const [openMenu, setOpenMenu] = useState(false);

  const pagCalc = Math.ceil(tableTotal / tableLimit);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      initialState: { pageSize: 5, pageIndex: 0 },
      pageCount: pagCalc,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    // canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: {
      // pageIndex,
      pageSize,
      globalFilter,
    },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    setTablePage(1);
    setTableLimit(value);
    setPageSize(value);
  };

  // Render the paginations
  const renderPagination = () => {
    const pagMap = [];
    for (let i = 0; i < pagCalc; i++) {
      pagMap.push(
        <MDPagination
          item
          key={i}
          onClick={() => {
            setTablePage(i < 1 ? 1 : i + 1);
            gotoPage(Number(i));
          }}
          active={tablePage - 1 === i}
        >
          {i + 1}
        </MDPagination>
      );
    }
    return pagMap;
  };

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => {
    setTablePage(value.value);
    gotoPage(Number(value.value - 1));
  };

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? 'desc' : 'asce';
    } else if (isSorted) {
      sortedValue = 'none';
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart =
    tablePage === 1 || tablePage === '' ? 1 : (tablePage - 1) * tableLimit + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (tablePage === 1 || tablePage === '') {
    entriesEnd = tableLimit;
  } else if (tablePage === renderPagination().length) {
    entriesEnd = tableTotal;
  } else {
    entriesEnd = tablePage * tableLimit;
  }

  const handleOpenMenu = (event, row) => {
    if (onRowSelected) onRowSelected(row);
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    if (onRowSelected) onRowSelected();
    setOpenMenu(false);
  };

  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = dark.main;

      colorValue = text.main;

      return colorValue;
    },
  });

  const renderButton = () => {
    return (
      <Menu
        anchorEl={openMenu}
        anchorReference={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        sx={{ mt: 2 }}
        aria-hidden={false}
      >
        {children.map((item) => item)}
      </Menu>
    );
  };

  return (
    <TableContainer sx={{ boxShadow: 'none' }}>
      {(showEntriesPerPage && entriesPerPage) || canSearch ? (
        <MDBox
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          p={3}
        >
          {showEntriesPerPage && entriesPerPage && (
            <MDBox display='flex' alignItems='center'>
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size='small'
                sx={{ width: '5rem' }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant='caption' color='secondary'>
                &nbsp;&nbsp;itens por página
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width='12rem' ml='auto'>
              <MDInput
                placeholder='Pesquisar...'
                value={search}
                size='small'
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component='thead'>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                const canBeSorted = column.canBeSorted ?? true;

                return (
                  <DataTableHeadCell
                    {...column.getHeaderProps(
                      isSorted &&
                        canBeSorted &&
                        column.getSortByToggleProps({ title: undefined })
                    )}
                    width={column.width ? column.width : 'auto'}
                    align={column.align ? column.align : 'left'}
                    sorted={canBeSorted ? setSortedValue(column) : false}
                  >
                    {column.render('Header')}
                  </DataTableHeadCell>
                );
              })}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.getCellProps().key.includes('action')) {
                    return (
                      <DataTableBodyCell
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : 'left'}
                        {...cell.getCellProps()}
                      >
                        <MDBox color='inherit'>
                          <IconButton
                            style={{ verticalAlign: 'top' }}
                            size='small'
                            disableRipple
                            color='inherit'
                            sx={navbarIconButton}
                            aria-controls='notification-menu'
                            aria-haspopup='true'
                            variant='contained'
                            onClick={(event) => handleOpenMenu(event, row)}
                          >
                            <MoreHorizIcon sx={iconsStyle} fontSize='medium' />
                          </IconButton>
                          {renderButton()}
                        </MDBox>
                      </DataTableBodyCell>
                    );
                  } else {
                    return (
                      <DataTableBodyCell
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : 'left'}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </DataTableBodyCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display='flex'
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent='space-between'
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        p={!showTotalEntries && pageOptions.length <= 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant='button'
              color='secondary'
              fontWeight='regular'
            >
              Mostrando {entriesStart} à {entriesEnd} de {tableTotal}{' '}
              {tableTotal === 1 ? 'item' : 'itens'}
            </MDTypography>
          </MDBox>
        )}
        {pageOptions.length > 1 && (
          <MDPagination
            variant={pagination.variant ? pagination.variant : 'gradient'}
            color={pagination.color ? pagination.color : 'info'}
          >
            {tablePage > 1 && (
              <MDPagination
                item
                onClick={() => {
                  setTablePage(Number(tablePage) - 1);
                  previousPage();
                }}
              >
                <Icon sx={{ fontWeight: 'bold' }}>chevron_left</Icon>
              </MDPagination>
            )}
            {renderPagination().length > 6 ? (
              <MDBox width='5rem' mx={1}>
                <MDInput
                  inputProps={{
                    type: 'number',
                    min: 1,
                    max: customizedPageOptions.length,
                  }}
                  value={tablePage}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </MDBox>
            ) : (
              renderPagination()
            )}
            {canNextPage && tablePage < pagCalc && (
              <MDPagination
                item
                onClick={() => {
                  setTablePage(Number(tablePage) + 1);
                  nextPage();
                }}
              >
                <Icon sx={{ fontWeight: 'bold' }}>chevron_right</Icon>
              </MDPagination>
            )}
          </MDPagination>
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DatatableApi.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  showEntriesPerPage: true,
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: 'gradient', color: 'info' },
  isSorted: true,
  noEndBorder: false,
  // setTableLimit: () => {},
  // tableLimit: 5,
  // setTablePage: () => {},
  // tablePage: 0,
  // tableTotal: 0,
};

// Typechecking props for the DataTable
DatatableApi.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  showEntriesPerPage: PropTypes.bool,
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(['contained', 'gradient']),
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  setTableLimit: PropTypes.func.isRequired,
  tableLimit: PropTypes.oneOf([5, 10, 15, 20, 25]).isRequired,
  setTablePage: PropTypes.func.isRequired,
  tablePage: PropTypes.number.isRequired,
  tableTotal: PropTypes.number.isRequired,
};

export default DatatableApi;
