import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    email: Yup.string()
      .email("Email inválido")
      .required("O Email é obrigatório"),
  }),
];

export const validationsToken = [
  Yup.object().shape({
    password: Yup.string().required("O senha é obrigatória"),
    // .max(12, "O campo deve ter no máximo 12 caracteres")
    // .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?!.*\W)(?=.*?[0-9]).{6,}$/, "Senha deve ter ao menos 6 carateres e o máximo 12 caracteres, uma letra maiúscula, uma letra minúscula e um número, sem caracteres especiais ou símbolos"),
    confirmPassword: Yup.string()
      .required("A confirmação de senha é obrigatória")
      .oneOf([Yup.ref("password")], "As senhas não são iguais"),
  }),
];

export default validations;
