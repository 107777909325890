/* eslint-disable no-unused-vars */
import { Chip, Collapse, Icon, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MDBox from 'shared/components/MDBox';
import MDProgress from 'shared/components/MDProgress';
import MDTypography from 'shared/components/MDTypography';
import toastError from 'shared/components/snackbar/error/toastError';
import DatatableApi from 'shared/components/tables/DatatableApi';
import sessionProgramsService from 'shared/services/session_programs/session_programs.service';

function ProgramTab({ itemData }) {
  const { uuid } = useParams();
  const [collapseId, setCollapseId] = useState();

  //datatable
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(25);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const handleToggle = (uuid) => {
    setCollapseId(collapseId === uuid ? null : uuid);
    // loadProgramDatable(uuid);
  };

  const loadProgramDatable = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await sessionProgramsService.list(
        `?page=${page}&limit=${limit}&patient=${uuid}&withObjectives=true&session=${itemData.uuid}${filterValue}`
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const percentageAnswerOfTotal = (qtdAnswer, totalAnswers) => {
    if (totalAnswers === 0) {
      return 0;
    }
    const percent = (qtdAnswer / totalAnswers) * 100;
    if (Number.isInteger(percent)) {
      return percent;
    } else {
      return percent.toFixed(2);
    }
  };

  const handleAnswersTable = (row, type, total) => {
    const { original } = row;
    const percentAnswer = percentageAnswerOfTotal(original[type], total || 0);

    return (
      <MDBox textAlign='center'>
        <MDTypography variant='h6'>{original[type]}</MDTypography>
        <MDTypography variant='body2'>{percentAnswer}%</MDTypography>
      </MDBox>
    );
  };

  const handleToggleObservations = (dataSessionProgram) => {
    const dataTableData = {
      columns: [
        {
          Header: 'Objetivo',
          accessor: 'objective',
          Cell: ({ value }) => (
            <MDTypography variant='h6'>{value ? value.name : ''}</MDTypography>
          ),
        },
        {
          Header: () => (
            <MDBox textAlign='center'>
              <Icon fontSize='medium' color='success'>
                check_circle_outline
              </Icon>
            </MDBox>
          ),
          accessor: 'correct',
          Cell: ({ cell: { row } }) =>
            handleAnswersTable(
              row,
              'correct',
              dataSessionProgram.program.attemps_number || 0
            ),
          width: '10%',
          canBeSorted: false,
        },
        {
          Header: () => (
            <MDBox textAlign='center'>
              <Icon fontSize='medium' color='warning'>
                check_circle_outline
              </Icon>
            </MDBox>
          ),
          accessor: 'correct_with_help',
          Cell: ({ cell: { row } }) =>
            handleAnswersTable(
              row,
              'correct_with_help',
              dataSessionProgram.program.attemps_number || 0
            ),
          width: '10%',
          canBeSorted: false,
        },
        {
          Header: () => (
            <MDBox textAlign='center'>
              <Icon fontSize='medium' color='error'>
                close
              </Icon>
            </MDBox>
          ),
          accessor: 'incorrect',
          Cell: ({ cell: { row } }) =>
            handleAnswersTable(
              row,
              'incorrect',
              dataSessionProgram.program.attemps_number || 0
            ),
          width: '10%',
          canBeSorted: false,
        },
      ],

      rows: dataSessionProgram.sessionObjectives || [],
    };

    return dataTableData;
  };

  useEffect(() => {
    loadProgramDatable();
  }, []);

  return (
    <MDBox py={2}>
      {dataTable && (
        <MDBox>
          {dataTable.data?.map((item) => (
            <Paper
              key={item.uuid}
              sx={{ mb: 2, p: 2, borderRadius: '10px', cursor: 'pointer' }}
              elevation={3}
            >
              <MDBox
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                onClick={() => handleToggle(item.uuid)}
              >
                <MDTypography variant='h5'>{item.program?.name}</MDTypography>
                <MDBox display='flex' alignItems='center'>
                  <Chip
                    label={item.active === 1 ? 'aplicado' : 'não aplicado'}
                    color={item.active === 1 ? 'success' : 'error'}
                    size='small'
                    sx={{ mr: 1 }}
                  />
                  <Icon
                    style={{
                      transform:
                        collapseId === item.uuid
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      transition: '0.3s',
                    }}
                  >
                    keyboard_arrow_down
                  </Icon>
                </MDBox>
              </MDBox>
              <Collapse in={collapseId === item.uuid}>
                <MDBox sx={{ mt: 2, p: 2, borderRadius: 1 }}>
                  <MDBox display='flex' flexDirection='column' gap={2}>
                    <MDBox>
                      <MDTypography variant='h6'>
                        Fase de aplicação
                      </MDTypography>
                      <MDTypography variant='body2'>
                        {item.phaseApplication?.name}
                      </MDTypography>
                    </MDBox>

                    <MDBox>
                      <MDTypography variant='h6'>Critério</MDTypography>
                      <MDTypography variant='body2'>
                        {item.program.criterion}
                      </MDTypography>
                    </MDBox>

                    <MDBox>
                      <MDTypography variant='h6'>
                        Reforçador da sessão
                      </MDTypography>
                      <MDTypography variant='body2'>
                        {item.suggested_reinforcer || ' - '}
                      </MDTypography>
                    </MDBox>

                    <MDBox>
                      {loadingData ? (
                        <MDProgress
                          variant='gradient'
                          variantProgress='indeterminate'
                          color='primary'
                        />
                      ) : (
                        <DatatableApi
                          table={handleToggleObservations(item)}
                          onRowSelected={setRowSelected}
                          entriesPerPage={{ defaultValue: tableLimit }}
                          setTableLimit={setTableLimit}
                          tableLimit={tableLimit}
                          setTablePage={setTablePage}
                          tablePage={tablePage}
                          tableTotal={tableTotal}
                          showEntriesPerPage={false}
                          showTotalEntries={false}
                        />
                      )}
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Collapse>
            </Paper>
          ))}
        </MDBox>
      )}
    </MDBox>
  );
}

ProgramTab.propTypes = {
  itemData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProgramTab;
