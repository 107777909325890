/* eslint-disable no-unused-vars */
import colors from 'assets/theme/base/colors';
import borders from 'assets/theme/base/borders';
import boxShadows from 'assets/theme/base/boxShadows';
import typography from 'assets/theme/base/typography';

// Material Dashboard 2 PRO React helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { white, primary } = colors;
const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

const { size, fontWeightRegular } = typography;

const StyleTabsInline = {
  tabsInline: {
    // backgroundColor: primary.main,
    borderRadius: 0,
    minHeight: 'unset',
    padding: pxToRem(4),
    '& .MuiTabs-indicator': {
      height: '100%',
      borderRadius: 0,
      backgroundColor: white.main,
      boxShadow: 0,
      transition: 'all 500ms ease',
      borderBottom: `4px solid ${primary.main} !important`,
    },
  },
  tabInline: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: size.md,
    flex: '0 0 auto',
    fontWeight: fontWeightRegular,
    textTransform: 'none',
    padding: pxToRem(4),
    px: pxToRem(15),
    borderBottom: '1px solid #EEEEEE !important',
    borderRadius: 0,
    color: `${primary.main} !important`,
    opacity: 1,
    '& .material-icons, .material-icons-round': {
      marginBottom: '10px !important',
      marginRight: pxToRem(6),
    },
    '& svg': {
      marginBottom: '0 !important',
      marginRight: pxToRem(6),
    },
  },
};

export default StyleTabsInline;
