const initialValues = {
  owner: "",
  start_date: "",
  title: "",
  day_week: "",
  start_hour: "",
  end_hour: "",
  recurrence: "",
};

export const initialValuesCalendarSearch = {
  owner: [],
  patient_uuid: [],
};

export const initialValuesAppointments = {
  date: "",
  start_hour: "",
  end_hour: "",
  therapist_uuid: "",
  patient_uuid: "",
};

export const initialValuesDeleteSchedule = {
  type: "event",
};

export default initialValues;
