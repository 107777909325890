import { isAxiosError } from 'axios';
import { SESSION_PROGRAM_API, SESSION_PROGRAMS_API } from './session_programs';
import customAxios from '../axiosCustom';
import errorStatusCode from 'utils/errorStatusCode';

class SessionProgramsService {
  read = async (uuid) => {
    try {
      const response = await customAxios.get(`${SESSION_PROGRAM_API}/${uuid}`);

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível carregar programas da sessão');
        }
      }

      throw new Error(error);
    }
  };

  list = async (filter = '') => {
    try {
      const response = await customAxios.get(
        `${SESSION_PROGRAMS_API}${filter}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível listar sessões');
        }
      }

      throw new Error(error);
    }
  };

  create = async ({
    session_uuid,
    program_uuid,
    patient_uuid,
    supervisor_uuid,
    name,
    application_phase,
    status,
    procedure,
    criterion,
    materials,
    supervisor_name,
    suggested_reinforcer,
    attemps_number,
  }) => {
    try {
      const response = await customAxios.post(
        `${SESSION_PROGRAM_API}/patient/${patient_uuid}`,
        {
          session_uuid,
          program_uuid,
          patient_uuid,
          supervisor_uuid,
          name,
          application_phase,
          status,
          procedure,
          criterion,
          materials,
          supervisor_name,
          suggested_reinforcer,
          attemps_number,
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível criar programa da sessão');
        }
      }

      throw new Error(error);
    }
  };

  edit = async (
    uuid,
    {
      session_uuid,
      program_uuid,
      patient_uuid,
      supervisor_uuid,
      supervisor_name,
      name,
      application_phase,
      status,
      procedure,
      criterion,
      materials,
      suggested_reinforcer,
      attemps_number,
    }
  ) => {
    try {
      const response = await customAxios.put(`${SESSION_PROGRAM_API}/${uuid}`, {
        session_uuid,
        program_uuid,
        patient_uuid,
        supervisor_uuid,
        supervisor_name,
        name,
        application_phase,
        status,
        procedure,
        criterion,
        materials,
        suggested_reinforcer,
        attemps_number,
      });

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível editar programa da sessão');
        }
      }

      throw new Error(error);
    }
  };

  updateStatus = async ({ uuid, status }) => {
    try {
      const response = await customAxios.patch(
        `${SESSION_PROGRAM_API}/${uuid}`,
        {
          status,
        }
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const defaultMsg = 'Não foi possível editar programa da sessão';
        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          case 404:
            throw new Error(error.response?.data);

          case 422:
            throw new Error(error.response?.data);

          default:
            throw new Error(defaultMsg);
        }
      }

      throw new Error(error);
    }
  };

  delete = async (uuid) => {
    try {
      const response = await customAxios.delete(
        `${SESSION_PROGRAM_API}/${uuid}`
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response.status;

        switch (status) {
          case 401:
            throw new Error(errorStatusCode(401));

          default:
            throw new Error('Não foi possível apagar programa da sessão');
        }
      }

      throw new Error(error);
    }
  };
}

export default new SessionProgramsService();
