import { validationCPF } from 'utils';
import { userLogged } from 'utils/essentialData';
import * as Yup from 'yup';

const loggedUser = userLogged() ?? undefined;

const validations = [
  Yup.object().shape({
    role: Yup.number().required('O perfil é obrigatório').nullable(),
    name: Yup.string().required('O nome é obrigatório'),
    phone: Yup.string().required('O telefone é obrigatório'),
    email: Yup.string()
      .email('Email inválido')
      .required('O email é obrigatório'),
    emergency_contact: Yup.string().required(
      'O contato emergencial é obrigatório'
    ),
    professional_role: Yup.string().required(
      'A formação profissional é obrigatória'
    ),
    admissiondate: Yup.date().required('A data de admissão é obrigatória'),
    // rpa_number_registration: Yup.string().required(
    //   "O número de Registro para RPA é obrigatório"
    // ),
    cep: Yup.string().required('O CEP é obrigatório'),
    address: Yup.string().required('O logradouro é obrigatório'),
    number: Yup.number().required('O número é obrigatório'),
    // complement: Yup.string().required("O complemento é obrigatório"),
    neighborhood: Yup.string().required('O bairro é obrigatório'),
    city: Yup.string().required('A cidade é obrigatória'),
    state: Yup.string().required('O estado é obrigatório'),
    birthdate: Yup.string().required('A data de nascimento é obrigatória'),
    document: Yup.string()
      .min(14, 'O mínimo de caracteres é 14')
      .test('document', 'O CPF digitado não é valido', (value, options) => {
        if (value === undefined || value === '') return true;
        if (value && value.length === 14) return validationCPF(value);
        return false;
      })
      .required('O CPF é obrigatório'),
    supervisor_uuids: Yup.array().when('role', {
      is: (value) => value && value === 2,
      then: Yup.array()
        .required('O supervisor é obrigatório')
        .min(1, 'Selecione ao menos 1 supervisor')
        .nullable(),
    }),
    // supervisor_uuid: Yup.object().when("role", {
    //   is: (value) => value && value === 2,
    //   then: Yup.object().required("O supervisor é obrigatório").nullable(),
    // }),
  }),
];

export const validationsEdit = [
  Yup.object().shape({
    role: Yup.number().required('O perfil é obrigatório').nullable(),
    name: Yup.string().required('O nome é obrigatório'),
    phone: Yup.string().required('O telefone é obrigatório'),
    email: Yup.string()
      .email('Email inválido')
      .required('O email é obrigatório'),
    emergency_contact: Yup.string().required(
      'O contato emergencial é obrigatório'
    ),
    professional_role: Yup.string().required(
      'A formação profissional é obrigatória'
    ),
    admissiondate: Yup.date().required('A data de admissão é obrigatória'),
    // rpa_number_registration: Yup.string().required(
    //   "O número de Registro para RPA é obrigatório"
    // ),
    cep: Yup.string().required('O CEP é obrigatório'),
    address: Yup.string().required('O logradouro é obrigatório'),
    number: Yup.number().required('O número é obrigatório'),
    // complement: Yup.string().required("O complemento é obrigatório"),
    neighborhood: Yup.string().required('O bairro é obrigatório'),
    city: Yup.string().required('A cidade é obrigatória'),
    state: Yup.string().required('O estado é obrigatório'),
    birthdate: Yup.string().required('A data de nascimento é obrigatória'),
    status: Yup.number().required('O status é obrigatório').nullable(),
    document: Yup.string()
      .min(14, 'O mínimo de caracteres é 14')
      .test('document', 'O CPF digitado não é valido', (value, options) => {
        if (value === undefined || value === '') return true;
        if (value && value.length === 14) return validationCPF(value);
        return false;
      })
      .required('O CPF é obrigatório'),
    supervisor_uuids: Yup.array().when('role', {
      is: (value) => value && value === 2,
      then: Yup.array()
        .required('O supervisor é obrigatório')
        .min(1, 'Selecione ao menos 1 supervisor')
        .nullable(),
    }),
    // supervisor_uuid: Yup.object().when("role", {
    //   is: (value) => value && value === 2,
    //   then: Yup.object().required("O supervisor é obrigatório").nullable(),
    // }),
  }),
];

export const evaluationsValidations = [
  Yup.object().shape({
    name: Yup.string()
      .required('O título da avaliação é obrigatório')
      .min(3, 'O título da avaliação deve ter no mínimo 3 caracteres'),

    date: Yup.date()
      .required('A data da avaliação é obrigatória')
      .typeError('Data inválida'),

    supervisor_uuid:
      loggedUser?.role === 99
        ? Yup.object().required('O supervisor é obrigatório').nullable()
        : Yup.object().nullable(),

    criterions: Yup.array().of(
      Yup.object().shape({
        note: Yup.number()
          .required('A nota é obrigatória')
          .integer('Deve ser número inteiro')
          .min(0, 'A nota deve ser no mínimo 0')
          .max(100, 'A nota deve ser no máximo 100'),
        comment: Yup.string()
          .max(255, 'O comentário não pode exceder 255 caracteres')
          .nullable(),
      })
    ),
  }),
];

export default validations;
