export function maskPhoneWithDDI(value) {
  if (!value) return "";
  let numberPhone = value;
  numberPhone = numberPhone.replace(/\D/g, "");
  numberPhone =
    numberPhone.length > 13 ? numberPhone.slice(0, -1) : numberPhone;
  numberPhone = numberPhone.replace(/^(\d{2})/, "+$1");
  numberPhone = numberPhone.replace(/^(\+\d{2})(\d{2})(\d{1})/, "$1($2)$3");
  numberPhone = numberPhone.replace(
    /^(\+\d{2})(\(\d{2}\))(\d{4,5}?)(\d{1,4})$/,
    "$1$2$3-$4"
  );
  return numberPhone;
}

export function maskPhone(value) {
  if (!value) return "";
  let numberPhone = value;
  if (value && value.includes("+")) numberPhone = value.slice(3);

  numberPhone = numberPhone.replace(/\D/g, "");
  numberPhone =
    numberPhone.length > 11 ? numberPhone.slice(0, -1) : numberPhone;
  numberPhone = numberPhone.replace(/^(\d{2})(\d{1})/, "($1)$2");
  numberPhone = numberPhone.replace(
    /^(\(\d{2}\))(\d{4,5}?)(\d{1,4})$/,
    "$1$2-$3"
  );
  return numberPhone;
}

export function maskCEP(value) {
  if (!value) return "";
  let numberCEP = value;
  numberCEP = numberCEP.replace(/\D/g, "");
  numberCEP = numberCEP.length > 8 ? numberCEP.slice(0, -1) : numberCEP;
  numberCEP = numberCEP.replace(/^(\d{5})(\d)/, "$1-$2");
  return numberCEP;
}

export function maskCPF(value) {
  if (!value) return "";
  let numberCPF = value || "";
  numberCPF = numberCPF.replace(/\D/g, "");
  numberCPF = numberCPF.length > 11 ? numberCPF.slice(0, -1) : numberCPF;
  numberCPF = numberCPF.replace(/^(\d{3})(\d{1,3})/, "$1.$2");
  numberCPF = numberCPF.replace(/^(\d{3})(\.\d{3})(\d{1,3}?)/, "$1$2.$3");
  numberCPF = numberCPF.replace(
    /^(\d{3})(\.\d{3})(\.\d{3})(\d{2})$/,
    "$1$2$3-$4"
  );
  return numberCPF;
}
