import { Card, Icon, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MDBox from 'shared/components/MDBox';
import MDProgress from 'shared/components/MDProgress';
import MDTypography from 'shared/components/MDTypography';
import ModalRegisterDetails from 'shared/components/modals/programs/modalRegisterDetails';
import toastError from 'shared/components/snackbar/error/toastError';
import DatatableApi from 'shared/components/tables/DatatableApi';
import sessionsService from 'shared/services/sessions/sessions.service';
import { formatDateFromApi } from 'utils/essentialData';

function HistorytList({ programId }) {
  const { uuid } = useParams();
  //datatable
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  // modal
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [currentItemHistory, setCurrentItemHistory] = useState();

  const loadSessions = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const res = await sessionsService.list(
        `?page=${page}&limit=${limit}&patient=${uuid}${filter}&program=${programId}`
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res.meta.total);
      }
    } catch (error) {
      toastError(error.message);
    } finally {
      setLoadingData(false);
    }
  };

  const HandleOptionsTable = (row) => {
    return (
      <IconButton
        title='download'
        onClick={() => {
          setOpenHistoryModal(true);
          setCurrentItemHistory(row.original.uuid);
        }}
      >
        <Icon>visibility</Icon>
      </IconButton>
    );
  };

  // datatable
  const dataTableData = {
    columns: [
      {
        Header: 'Terapeuta',
        accessor: 'therapist',
        Cell: ({ value }) => (value ? value.name : ''),
      },
      {
        Header: 'Data do atendimento',
        accessor: 'created_at',
        Cell: ({ value }) => (value ? formatDateFromApi(value) : ''),
        // width: "15%",
      },
      {
        Header: 'ações',
        accessor: 'options',
        width: '15%',
        Cell: ({ cell: { row } }) => HandleOptionsTable(row),
      },
    ],

    rows: dataTable?.data || [],
  };

  useEffect(() => {
    if (programId) loadSessions();

    return () => {
      setDataTable();
      setTableTotal();
    };
  }, [programId]);

  return (
    <>
      <Card sx={{ p: 3 }}>
        <MDBox display='flex' justifyContent='space-between'>
          <MDBox>
            <MDTypography variant='h4'>Historico de atendimentos</MDTypography>
          </MDBox>
        </MDBox>

        {loadingData ? (
          <MDProgress
            variant='gradient'
            variantProgress='indeterminate'
            color='primary'
          />
        ) : (
          <DatatableApi
            table={dataTableData}
            onRowSelected={setRowSelected}
            entriesPerPage={{ defaultValue: tableLimit }}
            setTableLimit={setTableLimit}
            tableLimit={tableLimit}
            setTablePage={setTablePage}
            tablePage={tablePage}
            tableTotal={tableTotal}
          />
        )}
      </Card>

      {currentItemHistory && (
        <ModalRegisterDetails
          open={openHistoryModal}
          onClose={() => {
            setOpenHistoryModal(false);
            setCurrentItemHistory();
          }}
          sessionId={currentItemHistory}
        />
      )}
    </>
  );
}

HistorytList.propTypes = {
  programId: PropTypes.string.isRequired,
};

export default HistorytList;
