/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Autocomplete, Card, Grid } from '@mui/material';
import MDBox from 'shared/components/MDBox';
import DashboardLayout from 'shared/examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'shared/examples/Navbars/DashboardNavbar';

import { useEffect, useState } from 'react';
import MDButton from 'shared/components/MDButton';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import initialValues from './schemas/initial_values';
import validations from './schemas/validations';
import FormField from 'shared/components/form_field';
import { setValue } from 'shared/components/autocomplete/autocompleteConfigs';
import { compareOptionToValue } from 'shared/components/autocomplete/autocompleteConfigs';
import patientsService from 'shared/services/patients/patients.service';
import FormFieldAutocomplete from 'shared/components/form_field/form_field_autocomplete';
import { handleValue } from 'shared/components/autocomplete/autocompleteConfigs';
import toastError from 'shared/components/snackbar/error/toastError';

function Treatments() {
  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState('');
  const [filterSearch, setFilterSearch] = useState('');

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const [patientData, setPatientData] = useState();

  const loadPatients = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await patientsService.list(
        `?page=${page}&limit=${limit}${filterValue}`
      );
      if (res) {
        setPatientData(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    const { patient_uuid, start_hour, end_hour } = values;

    // try {
    //   await UsersService.create(values);
    // toastSuccess(
    //   "Usuário criado com sucesso",
    // );
    //   actions.setTouched({});
    //   actions.setSubmitting(false);
    // } catch (e) {
    //   toastError(e.message);
    // }
  };

  useEffect(
    () => loadPatients(tablePage, tableLimit, filterSearch),
    [tablePage, tableLimit]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox>
          <Formik
            initialValues={initialValues}
            validationSchema={validations[0]}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleBlur,
            }) => {
              const {
                patient_uuid: patient_uuidV,
                start_hour: start_hourV,
                end_hour: end_hourV,
              } = values;
              return (
                <Form id='user-create-form' autoComplete='off'>
                  <Card id='basic-info' sx={{ overflow: 'visible', p: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Autocomplete
                          options={patientData?.data ?? []}
                          getOptionLabel={(opt) => opt.name ?? ''}
                          value={handleValue(patient_uuidV)}
                          isOptionEqualToValue={compareOptionToValue}
                          onChange={(e, value) =>
                            setValue(setFieldValue, 'patient_uuid', value)
                          }
                          renderInput={(params) => (
                            <FormFieldAutocomplete
                              fieldVariant='standard'
                              {...params}
                              fieldShrink
                              type='text'
                              label='Selecione um paciente'
                              placeholder='Pesquisar'
                              name='patient_uuid'
                              onBlur={handleBlur}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name='start_hour'
                          label='Data de inicio *'
                          fieldShrink
                          type='date'
                          value={start_hourV}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name='end_hour'
                          label='Data de fim'
                          fieldShrink
                          type='date'
                          value={end_hourV}
                        />
                      </Grid>
                    </Grid>

                    <MDBox width='10rem' ml='auto'>
                      <MDButton
                        disabled={isSubmitting}
                        color='primary'
                        type='submit'
                        size='large'
                        fullWidth
                      >
                        Buscar
                      </MDButton>
                    </MDBox>
                  </Card>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Treatments;
