/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Slide,
} from '@mui/material';

import dialog from 'assets/theme/components/dialog';
import dialogTitle from 'assets/theme/components/dialog/dialogTitle';
import dialogContent from 'assets/theme/components/dialog/dialogContent';
import dialogActions from 'assets/theme/components/dialog/dialogActions';
import MDTypography from 'shared/components/MDTypography';
import MDButton from 'shared/components/MDButton';
import MDBox from 'shared/components/MDBox';
import DatatableApi from '../tables/DatatableApi';
import MDProgress from '../MDProgress';
import toastError from '../snackbar/error/toastError';
import evaluationsService from 'shared/services/evaluations/evaluations.service';
import { formatDateFromApi } from 'utils/essentialData';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));

function ModalViewEvaluation({ open, onClose, evaluation }) {
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(25);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const [evaluationData, setEvaluationData] = useState();

  const loadEvaluation = async () => {
    try {
      const res = await evaluationsService.read(evaluation.original.uuid);
      if (res) {
        setEvaluationData(res);
        setDataTable(res.criterions);
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  // datatable
  const dataTableData = {
    columns: [
      {
        Header: 'Critério de avaliação',
        accessor: 'criterion_name',
        width: '50%',
      },
      {
        Header: 'Nota',
        accessor: 'note',
        Cell: ({ value }) => value,
        // width: "15%",
      },
      {
        Header: 'Comentário',
        accessor: 'comment',
      },
    ],

    rows: dataTable || [],
  };

  useEffect(() => {
    if (open) loadEvaluation();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={dialog}
      PaperProps={{
        sx: {
          minHeight: '90%',
        },
      }}
      fullWidth
      maxWidth='lg'
      aria-modal='true'
    >
      <DialogTitle sx={dialogTitle}>
        <MDBox display='flex' justifyContent='space-between'>
          <MDBox display='flex' alignItems='center' gap={1}>
            <Icon>visibility</Icon>
            <MDTypography
              color='text'
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              Visualizar avaliação
            </MDTypography>
          </MDBox>

          <IconButton onClick={() => onClose()}>
            <Icon fontSize='small'>clear</Icon>
          </IconButton>
        </MDBox>
      </DialogTitle>
      <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />

      <DialogContent sx={{ ...dialogContent, paddingY: 3 }}>
        <MDBox display='flex' flexDirection='column' gap={3}>
          <MDBox>
            <MDTypography variant='h6'> Titulo da avaliação</MDTypography>
            <MDTypography variant='body2'>
              {evaluationData?.name ? evaluationData.name : ''}
            </MDTypography>
          </MDBox>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <MDTypography variant='h6'> Supervisor responsável</MDTypography>
              <MDTypography variant='body2'>
                {evaluationData?.supervisor
                  ? evaluationData.supervisor.name
                  : ''}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDTypography variant='h6'> Data da avaliação</MDTypography>
              <MDTypography variant='body2'>
                {evaluationData?.date
                  ? formatDateFromApi(evaluationData?.date)
                  : ''}
              </MDTypography>
            </Grid>
          </Grid>

          {loadingData ? (
            <MDProgress
              variant='gradient'
              variantProgress='indeterminate'
              color='primary'
            />
          ) : (
            <DatatableApi
              table={dataTableData}
              onRowSelected={setRowSelected}
              entriesPerPage={{ defaultValue: tableLimit }}
              setTableLimit={setTableLimit}
              tableLimit={tableLimit}
              setTablePage={setTablePage}
              tablePage={tablePage}
              tableTotal={tableTotal}
              showTotalEntries={false}
              showEntriesPerPage={false}
            />
          )}
        </MDBox>
      </DialogContent>
      <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />
      <DialogActions sx={{ ...dialogActions, justifyContent: 'space-between' }}>
        <MDButton color='dark' variant='outlined' onClick={onClose}>
          cancelar
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

ModalViewEvaluation.defaultProps = {
  evaluation: null,
};

ModalViewEvaluation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  evaluation: PropTypes.any,
};

export default ModalViewEvaluation;
