import * as Yup from 'yup';

const validations = [
  Yup.object().shape({
    role: Yup.number().required('O perfil é obrigatório').nullable(),
    name: Yup.string().required('O nome é obrigatório'),
    phone: Yup.string().required('O telefone é obrigatório'),
    email: Yup.string()
      .email('Email inválido')
      .required('O Email é obrigatório'),
    emergency_contact: Yup.string().required('O telefone é obrigatório'),
    professional_role: Yup.string().required(
      'A formação profissional é obrigatória'
    ),
    admissiondate: Yup.date().required('A data de admissão é obrigatória'),
    rpa_number_registration: Yup.string().required(
      'O número de Registro para RPA ou MEI é obrigatório'
    ),
    cep: Yup.string().required('O cep é obrigatório'),
    address: Yup.string().required('O logradouro é obrigatório'),
    number: Yup.number().required('O número é obrigatório'),
    complement: Yup.string().required('O complemento é obrigatório'),
    neighborhood: Yup.string().required('O bairro é obrigatório'),
    city: Yup.string().required('A cidade é obrigatória'),
    state: Yup.string().required('O estado é obrigatório'),
    birthdate: Yup.string().required('A data de nascimento é obrigatória'),
  }),
];

export const validationsEdit = [
  Yup.object().shape({
    role: Yup.number().required('O perfil é obrigatório').nullable(),
    name: Yup.string().required('O nome é obrigatório'),
    phone: Yup.string().required('O telefone é obrigatório'),
    email: Yup.string()
      .email('Email inválido')
      .required('O Email é obrigatório'),
    emergency_contact: Yup.string().required('O telefone é obrigatório'),
    professional_role: Yup.string().required(
      'A formação profissional é obrigatória'
    ),
    admissiondate: Yup.date().required('A data de admissão é obrigatória'),
    // rpa_number_registration: Yup.string().required(
    //   "O número de Registro para RPA ou MEI é obrigatório"
    // ),
    cep: Yup.string().required('O cep é obrigatório'),
    address: Yup.string().required('O logradouro é obrigatório'),
    number: Yup.number().required('O número é obrigatório'),
    complement: Yup.string().required('O complemento é obrigatório'),
    neighborhood: Yup.string().required('O bairro é obrigatório'),
    city: Yup.string().required('A cidade é obrigatória'),
    state: Yup.string().required('O estado é obrigatório'),
    birthdate: Yup.string().required('A data de nascimento é obrigatória'),
    status: Yup.number().required('O status é obrigatório').nullable(),
  }),
];

export default validations;
