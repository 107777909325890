import { InputAdornment } from "@mui/material";

export const adormentCss = {
  ".MuiTypography-body1": {
    fontSize: "0.9rem",
  },
};

export const formFieldDDIAdornment = (value = "+55", position = "start") => (
  <InputAdornment position={position} sx={adormentCss}>
    {value}
  </InputAdornment>
);
