/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "@mui/material";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import logo from "assets/images/logo-clinica-espasso.png";
import MDButton from "shared/components/MDButton";
import AuthService from "shared/services/auth/auth.service";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import toastError from "shared/components/snackbar/error/toastError";
import base64 from "base-64";

import { Formik, Form } from "formik";
import initialValues from "./schemas/initial_values";
import validations, { validationsToken } from "./schemas/validations";
import FormField from "shared/components/form_field";

const defaultPageInfo = {
  title: "Recuperar Senha",
  textButton: "Enviar",
};

function ChangePassword() {
  const navigate = useNavigate();
  const token = useParams().token;
  const [textData, setTextData] = useState(defaultPageInfo);

  const changePassword = async (values, actions) => {
    const { email, password, confirmPassword } = values;

    const encoded = base64
      .encode(`${password}:${confirmPassword}`)
      .toString("base64");
    try {
      token
        ? await AuthService.changePassword(token, encoded)
        : await AuthService.sendEmail(email);

      token
        ? toastSuccess("Senha alterada com sucesso")
        : toastSuccess("Email enviado para recuperação!");
      actions.setTouched({});
      actions.setSubmitting(false);
      navigate("/");
    } catch (e) {
      toastError(e.message);
    }
  };

  const handlePageInfos = () => {
    if (token && textData.title === defaultPageInfo.title)
      setTextData({
        title: "Criar Senha",
        textButton: "Salvar",
      });
  };

  useEffect(() => {
    if (token) handlePageInfos();
  }, []);

  return (
    <BasicLayout>
      <MDBox
        p={2}
        mb={1}
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          marginBottom: 5,
        }}
      >
        <img alt="logo" src={logo} width="100%" />
      </MDBox>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            {textData.title}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={token ? validationsToken[0] : validations[0]}
            onSubmit={changePassword}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleBlur,
            }) => {
              const {
                email: emailV,
                password: passwordV,
                confirmPassword: confirmPasswordV,
              } = values;
              return (
                <Form id="change-password-form" autoComplete="off">
                  {!token ? (
                    <MDBox mb={2}>
                      <FormField
                        fieldVariant="outlined"
                        autoComplete="off"
                        name="email"
                        label="Email *"
                        type="text"
                        value={emailV}
                        error={errors.email && touched.email}
                        success={emailV.length > 0 && !errors.email}
                      />
                    </MDBox>
                  ) : (
                    <>
                      <MDBox mb={2}>
                        <FormField
                          fieldVariant="outlined"
                          name="password"
                          label="Senha *"
                          type="password"
                          value={passwordV}
                          error={errors.password && touched.password}
                          success={passwordV.length > 0 && !errors.password}
                        />
                      </MDBox>

                      <MDBox mb={2}>
                        <FormField
                          fieldVariant="outlined"
                          name="confirmPassword"
                          label="Confirmar Senha *"
                          type="password"
                          value={confirmPasswordV}
                          error={
                            errors.confirmPassword && touched.confirmPassword
                          }
                          success={
                            confirmPasswordV.length > 0 &&
                            !errors.confirmPassword
                          }
                        />
                      </MDBox>
                    </>
                  )}

                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{
                        cursor: "pointer",
                        color: "#015488",
                      }}
                      onClick={() => navigate("/")}
                    >
                      Voltar para o login
                    </MDTypography>
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton
                      disabled={isSubmitting}
                      color="primary"
                      type="submit"
                      size="large"
                      fullWidth
                    >
                      {textData.textButton}
                    </MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ChangePassword;
