import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    patient_uuid: Yup.object().required("O paciente é obrigatório").nullable(),
    start_hour: Yup.string().required("A data inicial é obrigatória"),
    // end_hour: Yup.string().required("A hora final é obrigatória"),
  }),
];

export default validations;
