/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import toastError from 'shared/components/snackbar/error/toastError';
import { Autocomplete, Grid, Icon } from '@mui/material';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import DataTableApi from 'shared/components/tables/DatatableApi';

import MDInput from 'shared/components/MDInput';
import MDButton from 'shared/components/MDButton';
import PopUp, { dialogButton } from 'shared/components/pop_up';
import { useParams } from 'react-router-dom';
import MDProgress from 'shared/components/MDProgress';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import NotificationItem from 'shared/examples/Items/NotificationItem';
import PopUpDelete from 'shared/components/pop_up/popUpDelete';
import behaviorsService from 'shared/services/behaviors/behaviors.service';
import { userLogged, translateAplicationPhase } from 'utils/essentialData';

const behaviorsStatusOptions = [
  {
    slug: 'all',
    value: 'Todos os comportamentos',
  },
  {
    slug: 'inactive',
    value: 'comportamentos inativos',
  },
  {
    slug: 'active',
    value: 'comportamentos ativos',
  },
];

function BehaviorsList({ setStep, setItemId }) {
  const { uuid } = useParams();

  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filterSearch, setFilterSearch] = useState('');

  const [filterStatus, setFilterStatus] = useState(behaviorsStatusOptions[0]);

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  //modal delete
  const [openPopUp, setOpenPopUp] = useState(false);
  //modal status
  const [openStatusPopUp, setOpenStatusPopUp] = useState(false);

  const loggedUserRole = userLogged()?.role ?? undefined;

  const permitionsButtons = () => [1, 99].includes(loggedUserRole);

  const dataTableData = {
    columns: [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Fase de aplicação',
        accessor: 'application_phase',
        Cell: ({ value }) =>
          value ? translateAplicationPhase(value.category) : '',
        // width: "15%",
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (value == true ? 'Ativo' : 'Inativo'),
        // width: "15%",
      },
      {
        Header: 'ações',
        accessor: 'action',
        width: '15%',
      },
    ],

    rows: dataTable?.data || [],
  };

  const loadBehaviors = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const filterValue = filter ? `&${filter}` : '';
      const res = await behaviorsService.list(
        `?page=${page}&limit=${limit}&patient=${uuid}${filterValue}`,
        setLoadingData
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  // modal delete
  const handleDelete = (row) => {
    setOpenPopUp(true);
  };
  const handleCancelDelete = () => setOpenPopUp(false);
  const handleApplyDelete = async () => {
    try {
      await behaviorsService.delete(rowSelected.original.uuid);
      toastSuccess('Comportamento apagado com sucesso');
      loadBehaviors(tablePage, tableLimit, filterSearch);
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenPopUp(false);
    }
  };

  // modal status
  const handleStatus = (row) => setOpenStatusPopUp(true);
  const handleCancelStatus = () => setOpenStatusPopUp(false);
  const handleApplyStatus = async () => {
    try {
      let behaviorStatus = rowSelected.original.status;
      if (behaviorStatus === 1) behaviorStatus = 0;
      else behaviorStatus = 1;

      const data = {
        uuid: rowSelected.original.uuid,
        status: behaviorStatus,
      };

      await behaviorsService.updateStatus(data);
      toastSuccess('Status do comportamento alterado com sucesso');
      loadBehaviors(tablePage, tableLimit, filterSearch);
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenStatusPopUp(false);
    }
  };

  useEffect(() => {
    if (filterStatus) {
      let filter = '';

      if (filterStatus.slug === 'inactive') filter = 'status=0';
      if (filterStatus.slug === 'active') filter = 'status=1';

      setFilterSearch(filter);
      loadBehaviors(tablePage, tableLimit, filter);
    }
  }, [filterStatus, tablePage, tableLimit]);

  return (
    <>
      <MDBox>
        <MDBox px={3} pt={3} lineHeight={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
              <MDTypography variant='gradient'>
                Comportamentos monitorados
              </MDTypography>
            </Grid>
            {permitionsButtons() && (
              <Grid item xs={12} sm={2}>
                <MDButton
                  variant='gradient'
                  color='primary'
                  size='small'
                  fullWidth
                  onClick={() => setStep('create')}
                >
                  Criar
                </MDButton>
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <Autocomplete
                defaultValue='all'
                options={behaviorsStatusOptions ?? []}
                value={filterStatus ?? null}
                getOptionLabel={(opt) => (opt?.value ? opt.value : '')}
                isOptionEqualToValue={(op, va) => op.slug === va.slug}
                onChange={(_, value) => {
                  setFilterStatus(value);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant='outlined' />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
        {loadingData ? (
          <MDProgress
            variant='gradient'
            variantProgress='indeterminate'
            color='primary'
          />
        ) : (
          <DataTableApi
            table={dataTableData}
            onRowSelected={setRowSelected}
            entriesPerPage={{ defaultValue: tableLimit }}
            setTableLimit={setTableLimit}
            tableLimit={tableLimit}
            setTablePage={setTablePage}
            tablePage={tablePage}
            tableTotal={tableTotal}
          >
            {[
              permitionsButtons() ? (
                <NotificationItem
                  onClick={() => {
                    const { uuid } = rowSelected.original;
                    setItemId(uuid);
                    setStep('edit');
                    // navigate(`/pacientes/editar/${uuid}/editar`);
                  }}
                  icon={<Icon>create</Icon>}
                  title='Editar'
                  key='option_edit'
                />
              ) : null,
              <NotificationItem
                onClick={() => {
                  handleStatus(rowSelected);
                }}
                icon={<Icon>compare_arrows</Icon>}
                title='Alterar status'
                key='option_status'
              />,
              <NotificationItem
                onClick={() => {
                  const { uuid } = rowSelected.original;
                  setItemId(uuid);
                  setStep('view_performance');
                  //   navigate(`/pacientes/editar/${uuid}/ver`);
                }}
                icon={<Icon>visibility</Icon>}
                title='Visualizar Desempenho'
                key='option_view'
              />,
              <NotificationItem
                onClick={() => {
                  handleDelete(rowSelected);
                }}
                icon={<Icon>delete</Icon>}
                title='Excluir'
                key='option_delete'
              />,
            ]}
          </DataTableApi>
        )}
      </MDBox>

      <PopUpDelete
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        title='Exclusão de comportamento'
        contentText='Tem certeza que deseja excluir este comportamento?'
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelDelete}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton color='primary' onClick={handleApplyDelete} key='confirm'>
            confirmar exclusão
          </MDButton>,
        ]}
      />

      <PopUp
        open={openStatusPopUp}
        onClose={() => setOpenStatusPopUp(false)}
        title='Alterar status'
        contentText={
          <>
            Deseja alterar o status deste comportamento para{' '}
            <strong>
              "{rowSelected?.original?.status === 1 ? 'Inativo' : 'Ativo'}"
            </strong>
            ?
          </>
        }
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelStatus}
            style={dialogButton}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton
            color='primary'
            onClick={handleApplyStatus}
            style={dialogButton}
            key='confirm'
          >
            Confirmar
          </MDButton>,
        ]}
      />
    </>
  );
}

BehaviorsList.defaultProps = {
  setStep: () => {},
};

BehaviorsList.propTypes = {
  setStep: PropTypes.func,
  setItemId: PropTypes.func.isRequired,
};

export default BehaviorsList;
