import PropTypes from "prop-types";

import { Divider } from "@mui/material";

import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";

function ErrorSnackbar({ title, content }) {
  return (
    <>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" color="dark" p={1.5}>
        <MDBox display="flex" alignItems="center" lineHeight={0}>
          <MDTypography variant="button" fontWeight="medium" color="error" textGradient>
            {title}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center" lineHeight={0}>
          <MDTypography variant="caption" color="dark">
            Agora
          </MDTypography>
        </MDBox>
      </MDBox>
      <Divider sx={{ margin: 0 }} light={false} />
      <MDBox
        p={1.5}
        sx={{
          fontSize: ({ typography: { size } }) => size.sm,
          color: ({ palette: { text } }) => text.main,
        }}
      >
        {content}
      </MDBox>
    </>
  );
}

ErrorSnackbar.defaultProps = {
  title: "Erro",
  content: "Ocorreu um erro inesperado",
};

ErrorSnackbar.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
};

export default ErrorSnackbar;
