import { Card, Icon } from '@mui/material';
import { useDateFilter } from 'context/DateFilterContext';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import SectorizedLineGraph from 'shared/components/charts/SectorizedLineGraph/sectorizedLineGraph';
import MDBox from 'shared/components/MDBox';
import MDButton from 'shared/components/MDButton';
import MDTypography from 'shared/components/MDTypography';
import ModalFilterGraph from 'shared/components/modals/modalFilterGraph/modalFilterGraph';
import toastError from 'shared/components/snackbar/error/toastError';
import behaviorsService from 'shared/services/behaviors/behaviors.service';
import { parseDateFromApi } from 'utils/essentialData';
import { downloadGraph } from 'utils/pdfFunctions';

const initialModalValues = {
  initial_date: '',
  final_date: '',
  type_date: 'all',
};

function GraphBehaviorPerformance({ itemData, setStep }) {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterSearch, setFilterSearch] = useState(initialModalValues);
  const [initialFilterValue, setInitialFilterValue] =
    useState(initialModalValues);

  const { setFirstGraphDate } = useDateFilter();

  const [graphData, setGraphData] = useState();
  const [registerType, setRegisterType] = useState(0);

  const chartRef = useRef(null);
  const chartContainerRef = useRef(null);

  const loadGraph = async (filter = '') => {
    try {
      setRegisterType(itemData.register_type);
      const res = await behaviorsService.getGraphData(itemData.uuid, filter);
      setGraphData(res);

      if (res.first_session_date && !filter) {
        const today = new Date();
        const firstSessionDate = parseDateFromApi(res.first_session_date);
        setFirstGraphDate(firstSessionDate);
        setInitialFilterValue((prevState) => ({
          ...prevState,
          initial_date: firstSessionDate,
          final_date: parseDateFromApi(today.toISOString()),
          type_date: 'all',
        }));
      }
    } catch (error) {
      toastError(error.message);
    }
  };

  const downloadGraphPdf = () =>
    downloadGraph(chartContainerRef, itemData.name);

  const formatFilter = (filterSearch) => {
    const { type_date, initial_date, final_date } = filterSearch;
    let filterString = '';

    if (type_date === 'all') return '';
    if (initial_date) {
      filterString += `startDate=${initial_date}`;
    }
    if (final_date) {
      filterString += filterString
        ? `&endDate=${final_date}`
        : `endDate=${final_date}`;
    }
    return filterString ? `?${filterString}` : '';
  };

  useEffect(() => {
    if (filterSearch) {
      const filter = formatFilter(filterSearch);
      loadGraph(filter);
    }
  }, [filterSearch]);

  useEffect(() => {
    if (itemData) {
      loadGraph();
    }

    return () => {
      setGraphData();
    };
  }, [itemData]);

  return (
    <>
      <Card sx={{ p: 3 }}>
        <MDBox display='flex' justifyContent='space-between'>
          <MDBox display='flex' gap={2}>
            <MDBox>
              <MDButton
                variant='outlined'
                color='primary'
                onClick={() => {
                  setStep('list');
                }}
                sx={{ height: 'fit-content' }}
              >
                Voltar
              </MDButton>
            </MDBox>
            <MDBox>
              <MDTypography variant='h4'>
                {itemData ? itemData.patient.name : ''}
              </MDTypography>
              <MDTypography variant='h5' sx={{ fontWeight: 400 }}>
                Comportamento: {itemData ? itemData.name : ''}
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox display='flex' gap={1}>
            <MDButton
              variant='outlined'
              color='primary'
              onClick={() => {
                setOpenFilter(true);
              }}
              sx={{ height: 'fit-content' }}
            >
              Filtrar
            </MDButton>
            <MDButton
              color='primary'
              onClick={() => downloadGraphPdf()}
              sx={{ height: 'fit-content' }}
            >
              Baixar
              <Icon>download</Icon>
            </MDButton>
          </MDBox>
        </MDBox>

        {graphData !== undefined && (
          <MDBox>
            <SectorizedLineGraph
              graphData={graphData}
              chartRef={chartRef}
              chartContainerRef={chartContainerRef}
              registerType={registerType}
            />
          </MDBox>
        )}
      </Card>

      <ModalFilterGraph
        open={openFilter}
        onClose={() => {
          setOpenFilter(false);
          setInitialFilterValue(filterSearch);
        }}
        setFilterValue={setFilterSearch}
        initialFilterValue={initialFilterValue}
      />
    </>
  );
}

GraphBehaviorPerformance.propTypes = {
  itemData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default GraphBehaviorPerformance;
