/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  */
import { Card, Grid, Icon, IconButton,  } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";

import MDInput from "shared/components/MDInput";
import { useEffect, useState } from "react";
import MDButton from "shared/components/MDButton";
import MeetingService from "shared/services/meetings/meetings.service";
import PopUp, { dialogButton } from "shared/components/pop_up";
import {  useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { formatDateFromApi } from "utils/essentialData";
import MDProgress from "shared/components/MDProgress";
import { renderPagination } from "shared/components/tableUtils";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";

// const dateOptions = {
//   // weekday: 'long',
//   year: "numeric",
//   month: "numeric",
//   day: "numeric",
//   timezone: "UTC",
//   hour: "2-digit",
//   minute: "2-digit",
// };

function MeetingList({ setMeetingStep, setMeetingId }) {
  const { uuid } = useParams();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  // const dataTableData = {
  //   columns: [
  //     {
  //       Header: "nome",
  //       accessor: "name",
  //     },
  //     {
  //       Header: "Responsável",
  //       accessor: "mother_name",
  //       Cell: ({ value }) => value,
  //     },
  //     {
  //       Header: "ações",
  //       accessor: "action",
  //       width: "15%",
  //     },
  //   ],

  //   rows: dataTable?.data || [],
  // };

  // const formatDateCard = (value) => {
  //   const startDate = value ? new Date(value) : null;
  //   const formatedDate = startDate
  //     ? startDate.toLocaleString("pt-BR", dateOptions)
  //     : null;
  //   const resDate = formatedDate.split(", ").join(" - ");
  //   return resDate;
  // };

  const handleCancelDelete = () => setOpenPopUp(false);

  const handleApplyDelete = async () => {
    try {
      await MeetingService.delete(rowSelected.original.uuid);
      toastSuccess("Orientação apagada com sucesso");
      window.location.reload();
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenPopUp(false);
    }
  };

  const loadMeetings = async (page = 1, limit = 5, filter = "") => {
    try {
      setLoadingData(true);
      const filterValue = filter !== "" ? `&filter=${filter.trim()}` : "";
      const res = await MeetingService.list(
        `/patient/${uuid}?page=${page}&limit=${limit}${filterValue}`
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handleChange = (event, value) => {
    if (value === "") {
      loadMeetings(1, tableLimit);
      setTablePage(1);
      setFilterSearch("");
    }
    setFilter(value);
  };

  const handleClickSearch = () => {
    setFilterSearch(filter.trim());
    setTablePage(1);
    loadMeetings(1, tableLimit, filter);
  };

  useEffect(
    () => loadMeetings(tablePage, tableLimit, filterSearch),
    [tablePage, tableLimit]
  );

  return (
    <>
      <Card sx={{ marginBottom: 1 }}>
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            Filtros
          </MDTypography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <MDInput
                type="text"
                name="filter"
                value={filter}
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: filter ? "visible" : "hidden" }}
                      size="small"
                      onClick={() => handleChange({ target: { value: "" } })}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2} display="flex">
              <MDButton
                disabled={!filter}
                variant="gradient"
                color="primary"
                size="small"
                fullWidth
                onClick={handleClickSearch}
              >
                BUSCAR
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDTypography variant="h5" fontWeight="medium">
                Orientações
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox width="12rem" ml="auto">
                <MDButton
                  variant="gradient"
                  color="primary"
                  size="small"
                  fullWidth
                  onClick={() => setMeetingStep("create")}
                >
                  NOVA ORIENTAÇÃO
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        {loadingData ? (
          <MDProgress
            variant="gradient"
            variantProgress="indeterminate"
            color="primary"
          />
        ) : (
          <MDBox p={3} lineHeight={1}>
            {dataTable.data?.map((value, i) => (
              <Card key={i} sx={{ marginBottom: 1 }}>
                <Grid container p={3}>
                  <Grid item xs={10} sm={11}>
                    <MDTypography variant="h5">
                      Orientação: {value.type}
                    </MDTypography>
                    <MDTypography variant="h6">
                      {formatDateFromApi(
                        value.date ? value.date : value.created_at
                      ) +
                        " - " +
                        value.user_name}
                    </MDTypography>
                    <MDTypography variant="h6">
                      Participantes: {value.participants}
                    </MDTypography>
                    {value.school && (
                      <MDTypography variant="h6">
                        Escola: {value.school}
                      </MDTypography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    display="flex"
                    // justifyContent="flex-end"
                    alignItems="flex-end"
                    flexDirection="column"
                  >
                    <IconButton
                      onClick={() => {
                        setMeetingId(value.uuid);
                        setMeetingStep("edit");
                      }}
                    >
                      <Icon>create</Icon>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <MDTypography variant="h6" color="primary">
                      Conteúdo:
                    </MDTypography>
                    <div
                      style={{ fontSize: "12px" }}
                      dangerouslySetInnerHTML={{ __html: value.description }}
                    />
                  </Grid>
                </Grid>
              </Card>
            ))}
            {/* pagination */}
            {dataTable.data && (
              <MDBox mt={2} display="flex" justifyContent="space-between">
                <MDBox></MDBox>
                {renderPagination(dataTable, tablePage, setTablePage)}
              </MDBox>
            )}
          </MDBox>
        )}

        {/* <DataTableApi
              table={dataTableData}
              onRowSelected={setRowSelected}
              entriesPerPage={{ defaultValue: tableLimit }}
              setTableLimit={setTableLimit}
              tableLimit={tableLimit}
              setTablePage={setTablePage}
              tablePage={tablePage}
              tableTotal={tableTotal}
            >
              {[
                <NotificationItem
                  onClick={() => {
                    const { uuid } = rowSelected.original;
                    navigate("/pacientes/editar/" + uuid);
                  }}
                  icon={<Icon>create</Icon>}
                  title="Editar"
                  key="option_edit"
                />,
                <NotificationItem
                  onClick={() => setOpenPopUp(true)}
                  icon={<Icon>delete</Icon>}
                  title="Deletar"
                  key="option_delete"
                />,
              ]}
            </DataTableApi> */}
      </Card>

      <PopUp
        open={openPopUp}
        onClose={() => setRowSelected()}
        title="Apagar orientação"
        contentText="Deseja apagar permanentemente esta orientação?"
        actions={[
          <MDButton
            color="dark"
            onClick={handleCancelDelete}
            style={dialogButton}
            key="cancel"
          >
            cancelar
          </MDButton>,
          <MDButton
            color="error"
            onClick={handleApplyDelete}
            style={dialogButton}
            key="confirm"
          >
            apagar
          </MDButton>,
        ]}
      />
    </>
  );
}

MeetingList.defaultProps = {
  setMeetingStep: () => {},
  setMeetingId: () => {},
};

MeetingList.propTypes = {
  setMeetingStep: PropTypes.func,
  setMeetingId: PropTypes.func,
};

export default MeetingList;
