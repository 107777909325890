/* eslint-disable no-unused-vars */
import { Chip, Collapse, Icon, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MDBox from 'shared/components/MDBox';
import MDProgress from 'shared/components/MDProgress';
import MDTypography from 'shared/components/MDTypography';
import toastError from 'shared/components/snackbar/error/toastError';
import DatatableApi from 'shared/components/tables/DatatableApi';
import behaviorsAbcService from 'shared/services/behaviors_abc/behaviors_abc.service';
import sessionBehaviorsService from 'shared/services/session_behaviors/session_behaviors.service';

function BehaviorTab({ itemData }) {
  const { uuid } = useParams();

  const [collapseId, setCollapseId] = useState();

  //datatable
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(25);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  //datatable 2
  const [dataTableBehavior, setDataTableBehavior] = useState([]);
  const [rowSelectedBehavior, setRowSelectedBehavior] = useState();
  const [loadingDataBehavior, setLoadingDataBehavior] = useState(false);
  const [tableLimitBehavior, setTableLimitBehavior] = useState(25);
  const [tablePageBehavior, setTablePageBehavior] = useState(1);
  const [tableTotalBehavior, setTableTotalBehavior] = useState(0);

  const handleToggle = (uuid) => {
    setCollapseId(collapseId === uuid ? null : uuid);
  };

  const handleToggleObservations = (dataSessionBehavior) => {
    const dataTableData = {
      columns: [
        {
          Header: 'Observações',
          accessor: 'observation',
        },
      ],
      rows: dataSessionBehavior.behaviorSessionObservations || [],
    };
    return dataTableData;
  };

  const loadBehaviorDatable = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await sessionBehaviorsService.list(
        `?page=${page}&limit=${limit}&patient=${uuid}&session=${itemData.uuid}&withObservations=true${filterValue}`
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const loadBehaviorsAbc = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingDataBehavior(true);
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await behaviorsAbcService.list(
        `?page=${page}&limit=${limit}&patient=${uuid}&session=${itemData.uuid}${filterValue}`
      );
      if (res) {
        setDataTableBehavior(res);
        setTableTotalBehavior(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingDataBehavior(false);
    }
  };

  // datatable
  const dataTableBehaviorData = {
    columns: [
      {
        Header: 'Antecedente',
        accessor: 'antecedent',
      },
      {
        Header: 'Comportamento',
        accessor: 'behavior',
      },
      {
        Header: 'Consequência',
        accessor: 'consequence',
      },
    ],

    rows: dataTableBehavior?.data || [],
  };

  useEffect(() => {
    if (itemData) {
      loadBehaviorDatable();
      loadBehaviorsAbc();
    }
  }, [itemData]);

  return (
    <MDBox py={2}>
      <MDBox>
        <MDTypography variant='h5'> Comportamentos monitorados </MDTypography>
        {dataTable && (
          <MDBox py={2}>
            {dataTable.data?.map((item) => (
              <MDBox key={item.uuid}>
                <MDBox
                  display='flex'
                  justifyContent='space-between'
                  p={2}
                  alignItems='center'
                >
                  <MDTypography> {item.behavior?.name} </MDTypography>

                  <MDTypography variant='h6'>
                    {item.behavior?.register_type == 'duration'
                      ? item.value
                      : `${item.value} vezes`}
                  </MDTypography>
                </MDBox>
                <Paper
                  key={item.uuid}
                  sx={{ mb: 2, p: 2, borderRadius: '10px', cursor: 'pointer' }}
                  elevation={3}
                >
                  <MDBox
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    onClick={() => handleToggle(item.uuid)}
                  >
                    <MDTypography variant='h5'>Observações</MDTypography>
                    <MDBox display='flex' alignItems='center'>
                      <Chip
                        label={
                          item.behaviorSessionObservations
                            ? item.behaviorSessionObservations.length
                            : 0
                        }
                        color={'success'}
                        size='small'
                        sx={{ mr: 1 }}
                      />
                      <Icon
                        style={{
                          transform:
                            collapseId === item.uuid
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                          transition: '0.3s',
                        }}
                      >
                        keyboard_arrow_down
                      </Icon>
                    </MDBox>
                  </MDBox>
                  <Collapse in={collapseId === item.uuid}>
                    <MDBox sx={{ mt: 2, p: 2, borderRadius: 1 }}>
                      <MDBox display='flex' flexDirection='column' gap={2}>
                        <MDBox>
                          {loadingData ? (
                            <MDProgress
                              variant='gradient'
                              variantProgress='indeterminate'
                              color='primary'
                            />
                          ) : (
                            <DatatableApi
                              table={handleToggleObservations(item)}
                              onRowSelected={setRowSelected}
                              entriesPerPage={{ defaultValue: tableLimit }}
                              setTableLimit={setTableLimit}
                              tableLimit={tableLimit}
                              setTablePage={setTablePage}
                              tablePage={tablePage}
                              tableTotal={tableTotal}
                              showEntriesPerPage={false}
                              showTotalEntries={false}
                            />
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Collapse>
                </Paper>
              </MDBox>
            ))}
          </MDBox>
        )}
      </MDBox>

      <MDBox>
        <MDTypography variant='h5'> Comportamentos ABC </MDTypography>
        <MDBox py={2}>
          {loadingDataBehavior ? (
            <MDProgress
              variant='gradient'
              variantProgress='indeterminate'
              color='primary'
            />
          ) : (
            <DatatableApi
              table={dataTableBehaviorData}
              onRowSelected={setRowSelectedBehavior}
              entriesPerPage={{ defaultValue: tableLimitBehavior }}
              setTableLimit={setTableLimitBehavior}
              tableLimit={tableLimitBehavior}
              setTablePage={setTablePageBehavior}
              tablePage={tablePageBehavior}
              tableTotal={tableTotalBehavior}
              showEntriesPerPage={false}
              showTotalEntries={false}
            />
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

BehaviorTab.propTypes = {
  itemData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default BehaviorTab;
