import { validationCPF } from "utils";
import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    document: Yup.string()
      .when("type", {
        is: (value) => value && value === "email",
        then: Yup.string()
          .email("e-mail inválido")
          .required("O e-mail é obrigatório"),
      })
      .when("type", {
        is: (value) => value && value === "cpf",
        then: Yup.string()
          .min(14, "O mínimo de caracteres é 14")
          .min(14, "O mínimo de caracteres é 14")
          .test("document", "O CPF digitado não é valido", (value, options) => {
            if (value === undefined || value === "") return true;
            if (value && value.length === 14) return validationCPF(value);
            return false;
          })
          .required("O CPF é obrigatório"),
      })
      .required("O CPF ou email é obrigatório"),
    password: Yup.string().required("A senha é obrigatória"),
  }),
];

export default validations;
