import PropTypes from 'prop-types';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import { formatDateFromApi } from 'utils/essentialData';

function ServiceTab({ itemData }) {
  return (
    <MDBox
      display='flex'
      flexDirection='column'
      gap={2}
      textAlign='left'
      py={2}
    >
      <MDBox>
        <MDTypography variant='h5'>Terapeuta responsável</MDTypography>
        <MDTypography variant='body2'>
          {itemData.therapist?.name ?? ''}
        </MDTypography>
      </MDBox>

      <MDBox>
        <MDTypography variant='h5'>Data de atendimento</MDTypography>
        <MDTypography variant='body2'>
          {itemData.created_at ? formatDateFromApi(itemData.created_at) : ' - '}
        </MDTypography>
      </MDBox>

      <MDBox>
        <MDTypography variant='h5'>
          Protocolo de atendimento da sessão
        </MDTypography>
        <MDTypography variant='body2'>
          {itemData.service_protocol ?? ' - '}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

ServiceTab.propTypes = {
  itemData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ServiceTab;
