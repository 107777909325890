const customLabelsPlugin = () => {
  return {
    id: 'customLabelsPlugin',
    beforeDraw: (chart) => {
      const { ctx, chartArea, data } = chart;
      const { datasets } = data;
      const width = chartArea.right - chartArea.left;

      ctx.save();
      ctx.font = 'bold 12px Arial';
      ctx.fillStyle = '#000';
      ctx.textAlign = 'center';

      // Define o deslocamento (em pixels) para mover os rótulos para a direita
      const offset = -10;

      // Calcula a largura total para cada conjunto de dados (A, B, C, etc)
      const totalDataCount = data.labels.length;

      // Mapeia as posições dos datasets
      const positions = datasets
        .map((dataset, index) => {
          const dataCount = dataset.data.filter((val) => val !== null).length;
          if (dataCount === 0) {
            return null; // Retorna null se o conjunto de dados estiver vazio
          }
          const sectionWidth = (dataCount / totalDataCount) * width;
          const start =
            chartArea.left +
            (index === 0
              ? 0
              : datasets.slice(0, index).reduce((sum, prevDataset) => {
                  const prevDataCount = prevDataset.data.filter(
                    (val) => val !== null
                  ).length;
                  return sum + (prevDataCount / totalDataCount) * width;
                }, 0));
          const end = start + sectionWidth;
          return { start, end, width: sectionWidth };
        })
        .filter((position) => position !== null);

      // Agora, vamos lidar com a verificação de todos os datasets
      const labels = {
        baseline: 'Linha de base',
        intervention: 'Intervenção',
        maintenance: 'Manutenção',
      };

      const datasetLabels = [
        {
          label: 'baseline',
          dataset: datasets.find((dataset) =>
            dataset.label.toLowerCase().includes('baseline')
          ),
          pos: 'A',
        },
        {
          label: 'intervention',
          dataset: datasets.find((dataset) =>
            dataset.label.toLowerCase().includes('intervenção')
          ),
          pos: 'B',
        },
        {
          label: 'maintenance',
          dataset: datasets.find((dataset) =>
            dataset.label.toLowerCase().includes('manutenção')
          ),
          pos: 'C',
        },
      ];

      // Inicializando as variáveis de posição
      let currentStartIndex = 0;

      // Para cada label, calcula as posições e desenha o texto
      datasetLabels.forEach(({ label, dataset, pos }) => {
        if (dataset) {
          // Se o dataset está presente, calcula a posição
          const position = positions[currentStartIndex];
          if (position) {
            const { start, end } = position;
            const midPosition = (start + end) / 2 + offset;
            ctx.fillText(pos, midPosition, chartArea.top - 20);
            ctx.fillText(labels[label], midPosition, chartArea.top - 5); // Desenha o label no gráfico
            currentStartIndex++; // Avança para o próximo conjunto de dados
          }
        }
      });

      // Ajuste dinâmico de posições se algum dataset estiver ausente
      // Recalcula as posições progressivamente conforme necessário
      let adjustedStartIndex = 0;
      if (!datasetLabels[0].dataset) {
        // Se não houver "baseline", o resto dos elementos devem ocupar a posição
        datasetLabels.slice(1).forEach(({ label, dataset, pos }) => {
          if (dataset && positions[adjustedStartIndex]) {
            const position = positions[adjustedStartIndex];
            const midPosition = (position.start + position.end) / 2 + offset;
            ctx.fillText(pos, midPosition, chartArea.top - 20);
            ctx.fillText(labels[label], midPosition, chartArea.top - 5);
            adjustedStartIndex++;
          }
        });
      }

      ctx.restore();
    },
  };
};

export default customLabelsPlugin;
