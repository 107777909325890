const mockUser = {
  role: 99,
  name: "Admin mock",
  phone: "(33)33333-3333",
  email: "fake@email.com",
  emergency_contact: "(44)44444-4444",
  professional_role: "mock",
  admissiondate: "2023-05-04",
  rpa_number_registration: "",
  cep: "01001-000",
  address: "Praça da Sé",
  number: 15,
  complement: "",
  neighborhood: "Sé",
  city: "São Paulo",
  state: "SP",
  birthdate: "2000-02-02",
  document: "748.814.150-42",
  supervisor_uuids: [],
};

export default mockUser;
