import React, { useState } from "react";

import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import { Form, Formik } from "formik";
import { Card } from "@mui/material";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";

import MDButton from "shared/components/MDButton";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";
import DropZoneFiles from "shared/components/dropzoneFiles";
import FileService from "shared/services/file_patients/file_patients.service";

import { fileValidations } from "shared/pages/patients/schemas/validations";
import { initialValuesFile } from "shared/pages/patients/schemas/initial_values";

function FileCreate({ setFileStep }) {
  const { uuid } = useParams();
  const [loadingValue, setLoadingValue] = useState(0);

  const handleSubmit = async (values, actions) => {
    const { file } = values;
    try {
      const formD = new FormData();
      formD.append("file", file);
      formD.append("type", Number(1));

      await FileService.create(uuid, formD, setLoadingValue);
      setLoadingValue(0);
      toastSuccess("Arquivo criado com sucesso");
      setFileStep("list");
    } catch (e) {
      toastError("Não foi possivel criar arquivo");
    }
  };

  return (
    <Formik
      initialValues={initialValuesFile}
      validationSchema={fileValidations[0]}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleBlur,
      }) => {
        const {
          file: fileV,
          //  type: typeV
        } = values;
        return (
          <Form id="user-edit-form" autoComplete="off">
            <Card id="basic-info" sx={{ overflow: "visible", p: 3 }}>
              <MDBox>
                <MDTypography variant="h5" pb={3}>
                  Criar arquivo de avaliação
                </MDTypography>
              </MDBox>

              {/* <MDBox>
                <Grid container direction="column">
                  <Grid item xs={12} sm={1.5}>
                    <MDTypography variant="body2">
                      Tipo do arquivo: &nbsp;&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <FormFieldRadio
                      name="type"
                      value={typeV}
                      options={radioOptionsFile}
                    />
                  </Grid>
                </Grid>
              </MDBox> */}

              <MDBox>
                <MDTypography variant="body2">Arquivo:</MDTypography>
                <DropZoneFiles
                  name="file_name"
                  required
                  value={fileV}
                  onChange={setFieldValue}
                  fieldName="file"
                  accept={"*"}
                  NameDescription={"Adicionar arquivo do paciente"}
                  loadingValue={loadingValue}
                />
              </MDBox>

              <MDBox display="flex" width="24rem" ml="auto" gap={3}>
                <MDButton
                  color="secondary"
                  type="button"
                  size="large"
                  onClick={() => setFileStep("list")}
                  fullWidth
                >
                  cancelar
                </MDButton>
                <MDButton
                  disabled={isSubmitting}
                  color="primary"
                  type="submit"
                  size="large"
                  fullWidth
                >
                  SALVAR
                </MDButton>
              </MDBox>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

FileCreate.defaultProps = {
  setFileStep: null,
};

FileCreate.propTypes = {
  setFileStep: PropTypes.func,
};

export default FileCreate;
