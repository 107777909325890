import * as Yup from 'yup';

const validations = [
  Yup.object().shape({
    name: Yup.string().required('O Critério é obrigatório'),
    role: Yup.number().required('O perfil é obrigatório').nullable(),
    status: Yup.number().required('O status é obrigatório').nullable(),
  }),
];

export default validations;
