import { Card } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MDBox from 'shared/components/MDBox';
import MDProgress from 'shared/components/MDProgress';
import MDTypography from 'shared/components/MDTypography';
import toastError from 'shared/components/snackbar/error/toastError';
import DatatableApi from 'shared/components/tables/DatatableApi';
import SessionsBehaviorObservationsService from 'shared/services/session_behavior_observations/session_behavior_observations.service';
import { formatDateFromApi } from 'utils/essentialData';

function RecordedObservationsList({ itemData }) {
  const { uuid } = useParams();

  //datatable
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const loadhistory = async (page = 1, limit = 10, filter = '') => {
    try {
      setLoadingData(true);
      const res = await SessionsBehaviorObservationsService.list(
        `?page=${page}&limit=${limit}&behavior=${itemData.uuid}&patient=${uuid}${filter}`
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res.meta.total);
      }
    } catch (error) {
      toastError(error.message);
    } finally {
      setLoadingData(false);
    }
  };

  // datatable
  const dataTableData = {
    columns: [
      {
        Header: 'Data do atendimento',
        accessor: 'created_at',
        Cell: ({ value }) => (value ? formatDateFromApi(value) : ''),
        // width: "15%",
      },
      {
        Header: 'Terapeuta',
        accessor: 'behaviorSession',
        Cell: ({ value }) => value.session?.therapist?.name ?? '',
      },
      {
        Header: 'Observações',
        accessor: 'observation',
      },
    ],

    // rows: dataTable || [],
    rows: dataTable?.data || [],
  };

  useEffect(() => {
    if (itemData) loadhistory();
  }, [itemData]);

  return (
    <Card sx={{ p: 3 }}>
      <MDBox display='flex' justifyContent='space-between'>
        <MDBox>
          <MDTypography variant='h4'>Observações registradas</MDTypography>
        </MDBox>
      </MDBox>

      {loadingData ? (
        <MDProgress
          variant='gradient'
          variantProgress='indeterminate'
          color='primary'
        />
      ) : (
        <DatatableApi
          table={dataTableData}
          onRowSelected={setRowSelected}
          entriesPerPage={{ defaultValue: tableLimit }}
          setTableLimit={setTableLimit}
          tableLimit={tableLimit}
          setTablePage={setTablePage}
          tablePage={tablePage}
          tableTotal={tableTotal}
        />
      )}
    </Card>
  );
}

RecordedObservationsList.defaultValue = {
  itemData: undefined,
};

RecordedObservationsList.propTypes = {
  itemData: PropTypes.any,
};

export default RecordedObservationsList;
