/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Chip, Grid, Icon, IconButton, useTheme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import DashboardLayout from 'shared/examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'shared/examples/Navbars/DashboardNavbar';
import DataTableApi from 'shared/components/tables/DatatableApi';

import MDInput from 'shared/components/MDInput';
import { useEffect, useState } from 'react';
import MDButton from 'shared/components/MDButton';
import NotificationItem from 'shared/examples/Items/NotificationItem';
import UsersService from 'shared/services/users/users.service';
import PopUp, { dialogButton } from 'shared/components/pop_up';
import { useNavigate } from 'react-router-dom';
import { translateRoleNumberToString } from 'utils/essentialData';
import MDProgress from 'shared/components/MDProgress';
import toastError from 'shared/components/snackbar/error/toastError';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import { userLogged } from 'utils/essentialData';

function Users() {
  const { palette } = useTheme();
  const { ternary, quartenary, quintenary, sextenary, white } = palette;

  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState('');
  const [filterSearch, setFilterSearch] = useState('');

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const loggedUser = userLogged() ?? undefined;

  const handleChips = (value) => {
    const role = value;
    let color = ternary.main;
    if (role === 99) color = quartenary.main;
    if (role === 1) color = quintenary.main;
    if (role === 2) color = sextenary.main;
    return (
      <Chip
        label={translateRoleNumberToString(role).toUpperCase()}
        style={{ backgroundColor: color, color: white.main, width: '100%' }}
      />
    );
  };

  const handlePhone = (value) => {
    if (!value || value === '') return ' - ';
    return value.includes('+') ? value : `+55 ${value}`;
  };

  const handlePermissions = () => {
    if (loggedUser?.role === 99) return true;
    return false;
  };

  const dataTableData = {
    columns: [
      {
        Header: 'nome',
        accessor: 'name',
      },
      {
        Header: 'telefone',
        accessor: 'phone',
        Cell: ({ value }) => handlePhone(value),
      },
      ...(handlePermissions()
        ? [
            {
              Header: 'perfil',
              accessor: 'role',
              Cell: ({ value }) => handleChips(value),
            },
          ]
        : []),
      // {
      //   Header: 'perfil',
      //   accessor: 'role',
      //   Cell: ({ value }) => handleChips(value),
      // },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (value === 0 ? 'Inativo' : 'Ativo'),
      },
      {
        Header: 'ações',
        accessor: 'action',
        width: '15%',
      },
    ],

    rows: dataTable?.data || [],
  };

  const handleCancelDelete = () => setOpenPopUp(false);

  const handleApplyDelete = async () => {
    try {
      let userStatus = rowSelected.original.status;
      if (userStatus === 1) userStatus = 0;
      else userStatus = 1;

      const data = {
        uuid: rowSelected.original.uuid,
        status: userStatus,
      };
      await UsersService.updateStatus(data);
      toastSuccess('Usuário editado com sucesso');
      loadUsers(tablePage, tableLimit, filterSearch);
    } catch (e) {
      if (e.message.includes('<p>')) {
        toastError(<div dangerouslySetInnerHTML={{ __html: e.message }} />);
      } else toastError(e.message);
    } finally {
      setOpenPopUp(false);
    }
  };

  const loadUsers = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      let filterRole = '';
      if (!handlePermissions()) filterRole = '&role=2';

      let filterQuery = `?page=${page}&limit=${limit}${filterRole}${filterValue}`;

      const res = await UsersService.list(filterQuery);
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (value === '') {
      loadUsers(1, tableLimit);
      setTablePage(1);
      setFilterSearch('');
    }
    setFilter(value);
  };

  const handleClickSearch = () => {
    setFilterSearch(filter.trim());
    setTablePage(1);
    loadUsers(1, tableLimit, filter);
  };

  useEffect(
    () => loadUsers(tablePage, tableLimit, filterSearch),
    [tablePage, tableLimit]
  );

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pb={3}>
          <MDBox mb={3}>
            <MDBox pb={1}>
              <Card>
                <MDBox p={3} lineHeight={1}>
                  <MDTypography variant='h5' fontWeight='medium'>
                    Filtros
                  </MDTypography>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <MDInput
                        type='text'
                        name='filter'
                        value={filter}
                        size='small'
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              sx={{ visibility: filter ? 'visible' : 'hidden' }}
                              size='small'
                              onClick={() =>
                                handleChange({ target: { value: '' } })
                              }
                            >
                              <ClearIcon />
                            </IconButton>
                          ),
                        }}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2} display='flex'>
                      <MDButton
                        disabled={!filter}
                        variant='gradient'
                        color='primary'
                        size='small'
                        fullWidth
                        onClick={handleClickSearch}
                      >
                        BUSCAR
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography variant='h5' fontWeight='medium'>
                      {handlePermissions() ? 'Usuários' : 'Terapeutas'}
                    </MDTypography>
                  </Grid>
                  {handlePermissions() && (
                    <Grid item xs={12} sm={6}>
                      <MDBox width='12rem' ml='auto'>
                        <MDButton
                          variant='gradient'
                          color='primary'
                          size='small'
                          fullWidth
                          onClick={() => navigate('/usuarios/criar')}
                        >
                          CRIAR
                        </MDButton>
                      </MDBox>
                    </Grid>
                  )}
                </Grid>
              </MDBox>
              {loadingData ? (
                <MDProgress
                  variant='gradient'
                  variantProgress='indeterminate'
                  color='primary'
                />
              ) : (
                <DataTableApi
                  table={dataTableData}
                  onRowSelected={setRowSelected}
                  entriesPerPage={{ defaultValue: tableLimit }}
                  setTableLimit={setTableLimit}
                  tableLimit={tableLimit}
                  setTablePage={setTablePage}
                  tablePage={tablePage}
                  tableTotal={tableTotal}
                >
                  {[
                    <NotificationItem
                      onClick={() => {
                        const { uuid } = rowSelected.original;
                        navigate('/usuarios/editar/' + uuid);
                      }}
                      icon={<Icon>create</Icon>}
                      title='Editar'
                      key='option_edit'
                    />,
                    <NotificationItem
                      onClick={() => {
                        const { status } = rowSelected.original;
                        setOpenPopUp(true);
                        setUserStatus(status);
                      }}
                      icon={<Icon>create</Icon>}
                      title='Alterar status'
                      key='option_delete'
                    />,
                  ]}
                </DataTableApi>
              )}
            </Card>
          </MDBox>
        </MDBox>
      </DashboardLayout>
      <PopUp
        open={openPopUp}
        onClose={() => setRowSelected()}
        title='Alterar status'
        contentText={
          <MDBox>
            Deseja alterar o status deste usuário para
            <strong> {userStatus === 1 ? 'Inativo' : 'Ativo'}</strong>?
          </MDBox>
        }
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelDelete}
            style={dialogButton}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton
            color='primary'
            onClick={handleApplyDelete}
            style={dialogButton}
            key='confirm'
          >
            confirmar
          </MDButton>,
        ]}
      />
    </>
  );
}

export default Users;
