import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import PropTypes from 'prop-types';

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Radio,
  Slide,
} from '@mui/material';

import dialog from 'assets/theme/components/dialog';
import dialogTitle from 'assets/theme/components/dialog/dialogTitle';
import dialogContent from 'assets/theme/components/dialog/dialogContent';
import dialogActions from 'assets/theme/components/dialog/dialogActions';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';
import { Form, Formik } from 'formik';
import FormField from '../form_field';
import MDButton from '../MDButton';
import {
  handleValue,
  noOptionsTextMinCharacters,
  renderInput,
} from '../autocomplete/autocompleteConfigs';
import DatatableApi from '../tables/DatatableApi';
import MDProgress from '../MDProgress';
import toastError from '../snackbar/error/toastError';
import patientsService from 'shared/services/patients/patients.service';
import UsersService from 'shared/services/users/users.service';
import { useDebounce } from 'use-debounce';
import { isNotEmpty } from 'utils';
import { formatDateFromApi } from 'utils/essentialData';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));

const initialValues = {
  name: '',
  patient_uuid: '',
  supervisor_uuid: '',
  register_date: '',
};

const validations = [
  Yup.object().shape({
    // initial_date: Yup.string().required('O nome é obrigatório'),
    // final_date: Yup.string().required('A fase de aplicação é obrigatória'),
    // type_date: Yup.string().required('O procedimento é obrigatório'),
  }),
];

function ModalPatientTemplates({
  open,
  onClose,
  setTemplateValue,
  requestApi,
  type,
}) {
  //datatable
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const [selectedItemTable, setSelectedItemTable] = useState();

  // patient list
  const [patientData, setPatientData] = useState();
  const [patientLoadingData, setPatientLoadingData] = useState(false);

  // supervisor list
  const [supervisorData, setSupervisorData] = useState();
  const [supervisorLoadingData, setSupervisorLoadingData] = useState(false);

  // debounce state
  const [name, setName] = useState('');
  const [patient, setPatient] = useState('');
  const [supervisor, setSupervisor] = useState('');
  const [date, setDate] = useState('');

  const [debouncedName] = useDebounce(name, 1000);
  const [debouncedPatient] = useDebounce(patient, 1000);
  const [debouncedSupervisor] = useDebounce(supervisor, 1000);
  const [debouncedDate] = useDebounce(date, 1000);

  const handleOnCloase = () => {
    onClose();
    setSelectedItemTable();
    setDataTable();
  };

  const handleSubmit = (values, actions) => {
    setTemplateValue(selectedItemTable);
    handleOnCloase();
  };

  const loadPatients = async (page = 1, limit = 5, filter = '') => {
    try {
      setPatientLoadingData(true);
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await patientsService.list(
        `?page=${page}&limit=${limit}${filterValue}`
      );
      if (res) {
        setPatientData(res);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setPatientLoadingData(false);
    }
  };

  const loadSupervisor = async (page = 1, limit = 5, filter = '') => {
    try {
      setSupervisorLoadingData(true);
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await UsersService.listTemplate(
        `?role=1&page=${page}&limit=${limit}${filterValue}`
      );
      setSupervisorData(res);
    } catch (e) {
      toastError(e.message);
    } finally {
      setSupervisorLoadingData(false);
    }
  };

  const loadRequestApi = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const filterValue = filter ?? '';

      const res = await requestApi.list(
        `?page=${page}&limit=${limit}${filterValue}`
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res.meta.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const dataTableData = {
    columns: [
      {
        Header: '',
        accessor: 'check_item',
        Cell: ({ cell: { row } }) => {
          const original = row.original;
          return (
            <Radio
              checked={selectedItemTable?.uuid == original?.uuid}
              onChange={() => setSelectedItemTable(original)}
              size='small'
            />
          );
        },
        width: '10%',
        canBeSorted: false,
      },
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Supervisor',
        accessor: 'supervisor',
        Cell: ({ value }) => (value ? value.name : ''),
        // width: "15%",
      },
      {
        Header: 'Paciente',
        accessor: 'patient',
        Cell: ({ value }) => (value ? value.name : ''),
        // width: "15%",
      },

      {
        Header: 'Data',
        accessor: 'created_at',
        Cell: ({ value }) => formatDateFromApi(value),
        // width: "15%",
      },
    ],

    // rows: dataTable || [],
    rows: dataTable?.data || [],
  };

  useEffect(() => {
    let filter = '';

    if (
      debouncedName ||
      debouncedPatient ||
      debouncedSupervisor ||
      debouncedDate
    ) {
      if (isNotEmpty(debouncedName)) filter += `&filter=${debouncedName}`;
      if (debouncedPatient) filter += `&patient=${debouncedPatient.uuid}`;
      if (debouncedSupervisor)
        filter += `&supervisor=${debouncedSupervisor.uuid}`;
      if (isNotEmpty(debouncedDate)) filter += `&date=${debouncedDate}`;

      loadRequestApi(1, 50, filter);
    }
  }, [debouncedName, debouncedPatient, debouncedSupervisor, debouncedDate]);

  return (
    <Dialog
      open={open}
      onClose={handleOnCloase}
      TransitionComponent={Transition}
      sx={dialog}
      fullWidth
      maxWidth='lg'
    >
      <DialogTitle sx={dialogTitle}>
        <MDBox display='flex' justifyContent='space-between'>
          <MDBox display='flex' alignItems='center' gap={1}>
            <Icon>warning</Icon>
            <MDTypography
              color='text'
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              {`Buscar templates de ${type}`}
            </MDTypography>
          </MDBox>

          <IconButton onClick={() => onClose()}>
            <Icon fontSize='small'>clear</Icon>
          </IconButton>
        </MDBox>
      </DialogTitle>
      <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />

      <Formik
        initialValues={initialValues}
        validationSchema={validations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleBlur,
        }) => {
          const {
            name: nameV,
            patient_uuid: patient_uuidV,
            supervisor_uuid: supervisor_uuidV,
            register_date: register_dateV,
          } = values;
          return (
            <Form id='user-edit-form' autoComplete='off'>
              <DialogContent sx={{ ...dialogContent, paddingY: 3 }}>
                <MDTypography color='text' variant='h6'>
                  Filtros
                </MDTypography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      name='name'
                      label={`Nome do ${type} *`}
                      type='text'
                      value={nameV}
                      error={errors.name && touched.name}
                      success={nameV.length > 0 && !errors.name}
                      onChange={(e) => {
                        setFieldValue('name', e.target.value);
                        setName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={patientData?.data || []}
                      noOptionsText={noOptionsTextMinCharacters(patientData)}
                      getOptionLabel={(op) => op.name}
                      value={handleValue(patient_uuidV)}
                      isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                      }
                      onChange={(e, value) => {
                        setFieldValue('patient_uuid', value);
                        setPatient(value);
                      }}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'patient_uuid',
                          'Paciente *',
                          handleBlur,
                          (event) => {
                            const { value } = event.target;
                            if (value && value.length >= 3) {
                              loadPatients(1, 50, value);
                            } else {
                              setPatientData();
                            }
                          }
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={supervisorData?.data ?? []}
                      noOptionsText={noOptionsTextMinCharacters(supervisorData)}
                      getOptionLabel={(op) => op.name}
                      value={handleValue(supervisor_uuidV)}
                      isOptionEqualToValue={(option, value) =>
                        option.uuid === value.uuid
                      }
                      onChange={(e, value) => {
                        setFieldValue('supervisor_uuid', value);
                        setSupervisor(value);
                      }}
                      renderInput={(params) =>
                        renderInput(
                          'form',
                          params,
                          'supervisor_uuid',
                          'Supervisor *',
                          handleBlur,
                          (event) => {
                            const { value } = event.target;
                            if (value && value.length >= 3) {
                              loadSupervisor(1, 50, value);
                            } else {
                              setSupervisorData();
                            }
                          }
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      fieldShrink
                      name='register_date'
                      label='Data de cadastro'
                      type='date'
                      value={register_dateV}
                      onChange={(e) => {
                        setFieldValue('register_date', e.target.value);
                        setDate(e.target.value);
                      }}
                      error={errors.register_date && touched.register_date}
                      success={
                        register_dateV.length > 0 && !errors.register_date
                      }
                    />
                  </Grid>
                </Grid>

                {loadingData ? (
                  <MDProgress
                    variant='gradient'
                    variantProgress='indeterminate'
                    color='primary'
                  />
                ) : (
                  <DatatableApi
                    table={dataTableData}
                    onRowSelected={setRowSelected}
                    entriesPerPage={{ defaultValue: tableLimit }}
                    setTableLimit={setTableLimit}
                    tableLimit={tableLimit}
                    setTablePage={setTablePage}
                    tablePage={tablePage}
                    tableTotal={tableTotal}
                  />
                )}
              </DialogContent>
              <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />
              <DialogActions
                sx={{ ...dialogActions, justifyContent: 'space-between' }}
              >
                <MDButton
                  color='dark'
                  variant='outlined'
                  onClick={handleOnCloase}
                >
                  cancelar
                </MDButton>
                <MDButton color='primary' type='submit'>
                  Usar Template
                </MDButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
}

ModalPatientTemplates.defaultProps = {
  onClose: null,
  setTemplateValue: () => {},
  type: 'programa',
  requestApi: () => {},
};

ModalPatientTemplates.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  setTemplateValue: PropTypes.func,
  type: PropTypes.oneOf(['programa', 'comportamento']),
  requestApi: PropTypes.any,
};

export default ModalPatientTemplates;
