/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import MDBox from 'shared/components/MDBox';
import GraphEvaluationList from './graph_evaluation_list';
import EvaluationCreate from './evaluations_create';
import EvaluationEdit from './evaluations_edit';

function getStepContent(step, setStep, item, setItem) {
  switch (step) {
    case 'graph_list':
      return (
        <GraphEvaluationList
          setStep={setStep}
          evaluation={item}
          setEvaluation={setItem}
        />
      );

    case 'evaluation_create':
      return <EvaluationCreate setStep={setStep} />;

    case 'evaluation_edit':
      return <EvaluationEdit setStep={setStep} evaluation={item} />;

    default:
      return null;
  }
}

function TherapistPerformance() {
  const [performanceStep, setPerformanceStep] = useState('graph_list');

  const [evaluation, setEvaluation] = useState('graph_list');

  return (
    <MDBox>
      {getStepContent(
        performanceStep,
        setPerformanceStep,
        evaluation,
        setEvaluation
      )}
    </MDBox>
  );
}

export default TherapistPerformance;
