/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import toastError from 'shared/components/snackbar/error/toastError';
import { Grid, Icon } from '@mui/material';
import MDBox from 'shared/components/MDBox';
import MDTypography from 'shared/components/MDTypography';
import DataTableApi from 'shared/components/tables/DatatableApi';

import MDButton from 'shared/components/MDButton';
import PopUp, { dialogButton } from 'shared/components/pop_up';
import { useParams } from 'react-router-dom';
import { formatDateFromApi } from 'utils/essentialData';
import MDProgress from 'shared/components/MDProgress';
import toastSuccess from 'shared/components/snackbar/success/toastSuccess';
import NotificationItem from 'shared/examples/Items/NotificationItem';
import PopUpDelete from 'shared/components/pop_up/popUpDelete';
import BehaviorsABCService from 'shared/services/behaviors_abc/behaviors_abc.service';

const behaviorsStatusOptions = [
  {
    slug: 'all',
    value: 'Todos os comportamentos',
  },
  {
    slug: 'inactive',
    value: 'comportamentos inativos',
  },
  {
    slug: 'active',
    value: 'comportamentos ativos',
  },
];

function BehaviorsABCList({ setStep }) {
  const { uuid } = useParams();

  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState('');
  const [filterSearch, setFilterSearch] = useState('');

  const [filterStatus, setFilterStatus] = useState(behaviorsStatusOptions[0]);
  const [filterStatusSearch, setFilterStatusSearch] = useState('');

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  //modal delete
  const [openPopUp, setOpenPopUp] = useState(false);
  //modal status
  const [openStatusPopUp, setOpenStatusPopUp] = useState(false);

  const dataTableData = {
    columns: [
      {
        Header: 'Antecedente',
        accessor: 'antecedent',
      },
      {
        Header: 'Comportamento',
        accessor: 'behavior',
        Cell: ({ value }) => value,
        // width: "15%",
      },
      {
        Header: 'Consequência',
        accessor: 'consequence',
        // width: "15%",
      },
      {
        Header: 'Data de registro',
        accessor: 'created_at',
        Cell: ({ value }) => formatDateFromApi(value),
        // width: "15%",
      },
      //   {
      //     Header: 'ações',
      //     accessor: 'action',
      //     width: '15%',
      //   },
    ],

    rows: dataTable?.data || [],
  };

  const loadBehaviorsAbc = async (page = 1, limit = 5, filter = '') => {
    try {
      setLoadingData(true);
      const filterValue = filter !== '' ? `&filter=${filter.trim()}` : '';
      const res = await BehaviorsABCService.list(
        `?page=${page}&limit=${limit}&patient=${uuid}${filterValue}`
      );
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  // modal delete
  const handleDelete = (row) => {
    setOpenPopUp(true);
  };
  const handleCancelDelete = () => setOpenPopUp(false);
  const handleApplyDelete = async () => {
    try {
      // await filePactientService.delete(rowSelected.original.uuid);
      toastSuccess('Comportamento apagado com sucesso');
      // loadFilePatients(tablePage, tableLimit, filterSearch);
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenPopUp(false);
    }
  };

  // modal status
  const handleStatus = (row) => {
    setOpenStatusPopUp(true);
  };
  const handleCancelStatus = () => setOpenStatusPopUp(false);
  const handleApplyStatus = async () => {
    try {
      // await filePactientService.delete(rowSelected.original.uuid);
      toastSuccess('Status do programa alterado com sucesso');
      // loadPrograms(tablePage, tableLimit, filterSearch);
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenStatusPopUp(false);
    }
  };

  useEffect(
    () => loadBehaviorsAbc(tablePage, tableLimit, filterSearch),
    [tablePage, tableLimit]
  );

  return (
    <>
      <MDBox>
        <MDBox px={3} pt={3} lineHeight={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
              <MDTypography variant='gradient'>Comportamentos ABC</MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        {loadingData ? (
          <MDProgress
            variant='gradient'
            variantProgress='indeterminate'
            color='primary'
          />
        ) : (
          <DataTableApi
            table={dataTableData}
            onRowSelected={setRowSelected}
            entriesPerPage={{ defaultValue: tableLimit }}
            setTableLimit={setTableLimit}
            tableLimit={tableLimit}
            setTablePage={setTablePage}
            tablePage={tablePage}
            tableTotal={tableTotal}
          >
            {[
              <NotificationItem
                onClick={() => {
                  const { uuid } = rowSelected.original;
                  setStep('edit');
                  //   navigate(`/pacientes/editar/${uuid}/editar`);
                }}
                icon={<Icon>create</Icon>}
                title='Editar'
                key='option_edit'
              />,
              <NotificationItem
                onClick={() => {
                  handleStatus(rowSelected);
                }}
                icon={<Icon>compare_arrows</Icon>}
                title='Alterar status'
                key='option_status'
              />,
              <NotificationItem
                onClick={() => {
                  const { uuid } = rowSelected.original;
                  setStep('view_performance');
                  //   navigate(`/pacientes/editar/${uuid}/ver`);
                }}
                icon={<Icon>visibility</Icon>}
                title='Visualizar Desempenho'
                key='option_view'
              />,
              <NotificationItem
                onClick={() => {
                  handleDelete(rowSelected);
                }}
                icon={<Icon>delete</Icon>}
                title='Excluir'
                key='option_delete'
              />,
            ]}
          </DataTableApi>
        )}
      </MDBox>

      <PopUpDelete
        open={openPopUp}
        onClose={() => setOpenPopUp(false)}
        title='Exclusão de comportamento'
        contentText='Tem certeza que deseja excluir este comportamento?'
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelDelete}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton color='primary' onClick={handleApplyDelete} key='confirm'>
            confirmar exclusão
          </MDButton>,
        ]}
      />

      <PopUp
        open={openStatusPopUp}
        onClose={() => setOpenStatusPopUp(false)}
        title='Alterar status'
        contentText={
          <>
            Deseja alterar o status deste comportamento para
            <strong>"Inativo"</strong>?
          </>
        }
        actions={[
          <MDButton
            color='dark'
            variant='outlined'
            onClick={handleCancelStatus}
            style={dialogButton}
            key='cancel'
          >
            cancelar
          </MDButton>,
          <MDButton
            color='primary'
            onClick={handleApplyStatus}
            style={dialogButton}
            key='confirm'
          >
            Confirmar
          </MDButton>,
        ]}
      />
    </>
  );
}

BehaviorsABCList.defaultProps = {
  setStep: () => {},
};

BehaviorsABCList.propTypes = {
  setStep: PropTypes.func,
};

export default BehaviorsABCList;
