import React from "react";

import PropTypes from "prop-types";
import { Form, Formik } from "formik";

import { Dialog, DialogContent, DialogTitle, Grid, Slide } from "@mui/material";

import dialog from "assets/theme/components/dialog";
import dialogTitle from "assets/theme/components/dialog/dialogTitle";
import dialogContent from "assets/theme/components/dialog/dialogContent";

import MDButton from "shared/components/MDButton";

import { initialValuesDeleteSchedule } from "../schemas/initial_values";
import { validationsDeleteSchedule } from "../schemas/validations";
import FormFieldRadio from "shared/components/form_field/form_field_radio";
import toastError from "shared/components/snackbar/error/toastError";
import toastSuccess from "shared/components/snackbar/success/toastSuccess";
import calendarService from "shared/services/calendar/calendar.service";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));
export const dialogButton = {
  width: "115px",
};

const radioOptions = [
  {
    value: "event",
    label: "Este evento",
  },
  {
    value: "this_following",
    label: "Este e os eventos seguintes",
  },
  {
    value: "calendar",
    label: "Todos eventos",
  },
];

function DeleteSchedule({ open, setOpen, onClose }) {
  const handleSubmit = async (values, actions) => {
    try {
      await calendarService.delete(open.data?.extendedProps?.uuid, values.type);
      toastSuccess("Agenda editada com sucesso");
      actions.setTouched({});
      actions.setSubmitting(false);
      onClose();
    } catch (e) {
      toastError(e.message);
    }
  };

  return (
    <Dialog
      open={open.open}
      // setOpen={setOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ ...dialog, textAlign: "center" }}
      //   fullWidth
      //   maxWidth="sm"
    >
      <DialogTitle sx={dialogTitle}> Deseja excluir compromisso? </DialogTitle>
      <DialogContent sx={dialogContent}>
        {/* <MDBox position="relative">
          <MDBox sx={{ ...dialogTitle, fontWeight: "bold" }}>{titleCard}</MDBox>
          <DialogContentText>supervisor 1</DialogContentText>
        </MDBox> */}
        <Formik
          initialValues={initialValuesDeleteSchedule}
          validationSchema={validationsDeleteSchedule[0]}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleBlur,
          }) => {
            const { type: typeV } = values;
            return (
              <Form
                id="user-create-form"
                autoComplete="off"
                style={{ overflow: "hidden", margin: "16px" }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <FormFieldRadio
                      name="type"
                      direction="column"
                      value={typeV}
                      options={radioOptions}
                      sx={{p:2}}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} mt={1}>
                  <Grid item xs={6} sm={6}>
                    <MDButton
                      color="dark"
                      onClick={() => setOpen({ ...open, open: false })}
                      style={dialogButton}
                      key="cancel"
                    >
                      cancelar
                    </MDButton>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <MDButton
                      disabled={isSubmitting}
                      color="primary"
                      type="submit"
                      style={dialogButton}
                      fullWidth
                    >
                      Confirmar
                    </MDButton>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

DeleteSchedule.defaultProps = {
  onClose: null,
};

DeleteSchedule.propTypes = {
  open: PropTypes.objectOf(PropTypes.any).isRequired,
  setOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default DeleteSchedule;
