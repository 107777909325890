import React from 'react';

import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  Slide,
} from '@mui/material';

import dialog from 'assets/theme/components/dialog';
import dialogTitle from 'assets/theme/components/dialog/dialogTitle';
import dialogContent from 'assets/theme/components/dialog/dialogContent';
import dialogContentText from 'assets/theme/components/dialog/dialogContentText';
import dialogActions from 'assets/theme/components/dialog/dialogActions';

import MDBox from '../MDBox';
import MDTypography from '../MDTypography';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));

function PopUpDelete({
  open,
  onClose,
  title,
  contentText,
  contentComponent,
  actions,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ ...dialog, textAlign: 'center' }}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle sx={dialogTitle}>
        <MDBox display='flex' justifyContent='space-between'>
          <MDBox display='flex' alignItems='center' gap={1}>
            <Icon>warning</Icon>
            <MDTypography
              color='text'
              sx={{ fontWeight: 'bold', fontSize: '16px' }}
            >
              {title}
            </MDTypography>
          </MDBox>

          <IconButton onClick={() => onClose()}>
            <Icon fontSize='small'>clear</Icon>
          </IconButton>
        </MDBox>
      </DialogTitle>
      <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />
      {contentText && (
        <DialogContent sx={{ ...dialogContent, paddingY: 5 }}>
          <DialogContentText sx={dialogContentText}>
            {contentText}
          </DialogContentText>
          {contentComponent}
        </DialogContent>
      )}
      <Divider sx={{ borderBottom: '1px solid', margin: 0 }} />
      <DialogActions sx={{ ...dialogActions, justifyContent: 'space-between' }}>
        {actions}
      </DialogActions>
    </Dialog>
  );
}

PopUpDelete.defaultProps = {
  onClose: null,
  contentText: null,
  contentComponent: null,
};

PopUpDelete.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  contentText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  contentComponent: PropTypes.node,
  actions: PropTypes.node.isRequired,
};

export default PopUpDelete;
